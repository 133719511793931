import React from 'react';
import ReactDOM from 'react-dom';
import {addToCart, loadCurrentItem, loadProduct} from "../../actions/CartAction";
import ReactModal from 'react-modal';

import * as linksType from "../../axios/ApiPath";
import connect from "react-redux/lib/connect/connect";
import $ from 'jquery';
import Slider from "react-slick";

class ProductDetailPopUp extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            showModal: this.props.showModal || false,
            product: this.props.product,


        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

    }

    componentDidMount(){
        document.addEventListener('keyup', function (e) {
                console.log(e.key)
            if (e.key == "Escape") {
                this.setState({showModal: false})
            }
        }.bind(this))

    }

    handleOpenModal() {

        var script = document.createElement('script')
        script.src = '/js/custom.min.js';
        script.async = true;
        document.body.appendChild(script);

        this.setState({showModal: true});

    }

    handleCloseModal(product_id) {

        if (product_id === 'close') {
            this.setState({showModal: false});
        } else {
            this.props.addToCart(this.state.product.id)
            this.setState({showModal: false});
        }

    }

    render() {
        var multiImg = 1;

        multiImg = this.state.product.product_images.map((mult) => {


            return (
                // <div>
                //     <figure>
                <img src={linksType.Local_Image_Path + "products/" + mult.product_images} className="img-fluid"
                     alt=""
                />
                //     </figure>
                // </div>
            )


        })
        if (multiImg == false) {
            multiImg = (


                // <div>
                //     <figure>
                <img src={linksType.Local_Image_Path + "products/" + this.state.product.product_image}
                     className="img-fluid"
                     alt=""
                />
                //     </figure>
                // </div>


            )
        }


        return (

            <React.Fragment>

                <div className="col-lg-3 col-md-4 col-sm-6" key={this.state.product.id}>

                    <div className="stepBox">
                        <figure>
                            {/* <a href="#" data-toggle="modal" data-target="#productModal" onClick={(d) => { */}
                            <a href="javascript:void(0)" onClick={this.handleOpenModal}><img
                                src={linksType.Local_Image_Path + "products/" + this.state.product.product_image}
                                className="img-fluid" alt="img"/></a>
                            {/*<button onClick={(e) => addToCart(e,product.id)} className="themeBtn">Add to Box</button>*/}
                            <a href="javascript:void(0)" onClick={(e) => this.props.addToCart(this.state.product.id)}
                               className="themeBtn">Add to BOKS</a>
                        </figure>
                        <h2><span>{this.state.product.product_name.substr(0, 50) + (this.state.product.product_name.length > 50 ? '...' : '') ?? ''}</span><strong>
                            {this.state.product.product_title??''}
                           </strong></h2>

                        <span className="dlr">${this.state.product.product_current_price}</span>
                    </div>
                </div>

                <ReactModal

                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example">
                    <a className="modal-close" onClick={(e) => this.handleCloseModal('close')}>Close </a>

                    <div className="row">

                        <div className="col-md-6">

                            <div className="single-item">

                                {
                                    multiImg ?? ''}


                            </div>
                            <div className="single-item-nav">
                                {multiImg ?? ""}
                            </div>
                        </div>


                        <div className="col-md-6 text-center">
                            <div className="product-modal product-modal12">
                                <div id="productModal">
                                    <h2>{this.state.product.product_name ?? ''}</h2>
                                    <p dangerouslySetInnerHTML={{__html: this.state.product.description ?? ''}}></p>

                                    <a href="javascript:void(0)"
                                       onClick={(e) => this.handleCloseModal(this.state.product.id)}
                                       className="themeBtn btn d-btn btn-brown-pattern w-100">Add to BOKS
                                        ${this.state.product.product_current_price}</a>
                                </div>
                            </div>
                        </div>

                    </div>

                </ReactModal>


            </React.Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (id) => dispatch(addToCart(id)),
        loadCurrentItem: (item) => dispatch(loadCurrentItem(item)),
    };
};

export default connect(null, mapDispatchToProps)(ProductDetailPopUp);
import React, {Component} from 'react';
import Header from "../../component/include/Header";
import Footer from "../../component/include/Footer";
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import $ from 'jquery';
import {Link, NavLink} from 'react-router-dom';
import Helmet from "react-helmet";
class ContactUs extends Component {
    constructor(props) {

        super(props);
        const profileData = localStorage.getItem('user')
        var data = JSON.parse(profileData)
        this.state = {
          user_id:data.id, name: null, message: null, email: null,
            msg:null
            //     AllData: [],
            //     totalCartPrice:0
        }

    }
    handleSubmit = e => {
        e.preventDefault();
        var fewSeconds = 5;
        // Ajax request
        var btn = $("#btn");
        btn.prop('disabled', true);
        setTimeout(function () {
            btn.prop('disabled', false);
        }, fewSeconds * 1000);


        const contactUs = {

            name: this.name,
            email: this.email,
            message: this.message,
            user_id:this.state.user_id

        }
    console.log(contactUs)

        const cardResponse = axios.post(linksType.Local_API_PATH + 'contact-us',  contactUs
        ).then((res) => {
            console.log(res.data);
            if (res.data === 'succeeded') {
                let msg = 'Thank you For Contact us We Will contact Soon';
                this.setState({
                    msg: msg,
                })

            } else {
                let msg = 'SomeThing Went Wrong ';
                this.setState({
                    msg: msg,
                })
                var btn = $("#btn");
                btn.prop('disabled', false);
            }


        }).catch((err) => {
            console.log(err.response.status)
            // if(err.response.status==401){
            //     const {history} = this.props;
            //     history.push("/logout")
            // }
        });
    }


    render() {

        return (
            <React.Fragment>
                <Header/>
                <Helmet>
                    <title>RealBoks | Contact Us</title>
                </Helmet>
                <section className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>CONTACT</h2>
                                <ul>
                                    <li><Link to="/">HOME</Link></li>
                                    <li><span>CONTACT US</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="contactPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="contactContent">
                                    <h2>Get In Touch</h2>
                                    <p>Want to get in touch or share your LuxBOKS ideas? We would love to hear from you! Feel free to contact us.</p>
                                    <label>By Phone</label>
                                    <a href="tel:859-588-8955">859-588-8955</a>
                                    <label>By Email</label>
                                    <a href="mailto:info@realboks.com">info@realboks.com</a>
									{/* <label>Address</label>
                                    <span>Lorem ipsum 12354 Hearts Ave, Longwood, USA</span>   */}                                       
							   </div>
                            </div>
                            <div className="col-md-7">
                                <div className="contactForm">
                                    <form onSubmit={this.handleSubmit}>
                                        <input type="text" placeholder="Name "  required onChange={e => this.name= e.target.value}/>
                                        <input type="email" placeholder="Email " required  onChange={e => this.email = e.target.value}/>
                                        <textarea placeholder="Message"   onChange={e => this.message = e.target.value}></textarea>
                                        {/*<div className="agreCheck">*/}
                                        {/*    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>*/}
                                        {/*    <label htmlFor="vehicle1"> I agree that my submitted data is being*/}
                                        {/*        collected and stored.</label>*/}
                                        {/*</div>*/}
                                        <p>{this.state.msg??''}</p>
                                        <button className="themeBtn btn-1c" id="btn">Submit </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*<section>*/}
                {/*    <div className="map">*/}
                {/*        <iframe*/}
                {/*            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13004082.928417291!2d-104.65713107818928!3d37.275578278180674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1615455673542!5m2!1sen!2s"*/}
                {/*            width="100%" height="450" style="border:0;" allowFullScreen="" loading="lazy"></iframe>*/}
                {/*    </div>*/}
                {/*</section>*/}
                <Footer/>
            </React.Fragment>


        )
    }
}

export default ContactUs;
import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Header from "../../component/include/Header";
import connect from "react-redux/lib/connect/connect";
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import {adjustCartQty, clearCart, removeFromRowCart} from "../../actions/CartAction";
import PaymentMethod from "../payment/PaymentMethod";
import Helmet from "react-helmet";

class Checkout extends Component {

    constructor(props) {

        super(props);
        const profileData = localStorage.getItem('user')
        var data = JSON.parse(profileData)
        this.state = {
            user_id:data.id,first_name:data.first_name, last_name: data.last_name, email: data.email, phone: null,
            address: null, country: null, city: null, zip: null, state: null,
            AllData: null,
            totalCartPrice:0,
            core_shipping:null,premium_shipping:null,
             prem_flag:0,
             core_flag:0,
             read_core:0,
             read_prem:0,
        }


    }


    componentDidMount() {

        let token=localStorage.getItem('token');
        const response = axios.get(linksType.Local_API_PATH+'getShippingPrice',{
            headers: {"Authorization" : `Bearer ${token}`}
        })
            .then((res) => {

                    this.setState({
                        core_shipping:res.data.core_shipping,
                        premium_shipping:res.data.premium_shipping,
                    })
            }).catch ((err)=> {

                if(err.response.status==401){
                    const {history} = this.props;
                    history.push("/logout")
                }else{
                    const {history} = this.props;
                    history.push("/logout")
                }
        })

        if(this.props.cart.length==0){

                const {history} = this.props;
                history.push("/")

        }
        let items = 0;
        let price = 0;
        let product_ids = 0;

        const product=this.props.cart
        product.forEach((item) => {
            if(item['type']==='box') {
                price+=item['total']*item['boxCartQty'];
                if( item['SetPackageName']==55){
                    this.setState({
                        prem_flag:1
                })
                }if(item['SetPackageName']==40){
                    this.setState({
                        core_flag:1
                    })
                }
            }

            else{
               if(item['relatedProduct'].length <=6){
                   this.setState({
                       read_core:1
                   })
               }if(item['relatedProduct'].length >=7 ){
                    this.setState({
                        read_prem:1
                    })
                }
                price +=item['total']*item['readyToshipQty']
            }
            // item['cartProdcuts'].forEach(data => {
            //     items += data.qty;
            //     price += data.qty * data.product_current_price;
            //     product_ids = data.id;
            // })




        })
        this.setState({
            totalCartPrice:price
        })
        //


    }

    handleSubmit = e => {
        e.preventDefault();


        const formData = {
            user_id:this.state.user_id,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone: this.phone,
            country: this.country,
            city: this.city,
            zip: this.zip,
            state: this.country_state,
            address:this.address,
            shipping_total:localStorage.getItem('ship')

        }

        const CartData = JSON.stringify(this.props.cart)
        // const checkoutForm = JSON.stringify(formData);
        // this.setState({
        //     AllData:checkoutForm
        // })

        // const cardResponse = axios.post(linksType.Local_API_PATH + 'checkout', {
        //         data: CartData,
        //         formData: checkoutForm
        //     }
        // ).then((res) => {
        //     console.log(res.data)
        //
        //
        // }).catch((err) => {
        //     console.log(err);
        // });
        //

        // this.props.clearCart();

         const {history} = this.props;
          history.push("/PaymentMethod",formData)


    }

    render() {
        var ship_total=0;
        if(this.state.core_flag==1 && this.state.prem_flag==1 && this.state.read_core==1 && this.state.read_prem==1
                || this.state.core_flag==1 && this.state.prem_flag==1 || this.state.read_core==1 && this.state.read_prem==1 ||
            this.state.core_flag==1 &&  this.state.read_prem==1 || this.state.prem_flag==1 && this.state.read_core==1){

                ship_total =parseFloat(this.state.core_shipping)+parseFloat(this.state.premium_shipping)
        }else{
            ship_total =parseFloat(this.state.core_shipping)

        }
        localStorage.setItem('ship',ship_total)

        return (
            <React.Fragment>
                <Header/>
                <Helmet>
                    <title>RealBoks | Checkout</title>
                </Helmet>
                <section className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Checkout</h2>
                                <ul>
                                    <li><Link to="/">HOME</Link></li>
                                    <li><span>Checkout</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="shopPage">
                    <div className="container">
                        <div className="row">


                            <div className="col-md-12">
                                <div className="check-out-form">
                                    <p className="checkout-subheading">
                                        {/*<a href="#">Already Registered?</a> Click here to <a href="#"*/}
                                        {/*                                                     className="text-uppercase">Login*/}
                                        {/*now</a>*/}
                                    </p>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="">first NamE</label>
                                                <input type="text" name="first_name"
                                                       onChange={e => this.first_name = e.target.value}
                                                           className="form-control" value={this.state.first_name} required/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">Last NamE</label>
                                                <input type="text" onChange={e => this.last_name = e.target.value}
                                                       name="last_name" className="form-control "
                                                       value={this.state.last_name} required/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">email address</label>
                                                <input type="email" name="email"
                                                       onChange={e => this.email = e.target.value}
                                                       className="form-control" value={this.state.email} required/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">Phone</label>
                                                <input type="number" name="phone"
                                                       onChange={e => this.phone = e.target.value}
                                                       className="form-control" required />
                                            </div>
                                            {/*<div className="col-md-6">*/}
                                            {/*    <label htmlFor="">password</label>*/}
                                            {/*    <input type="text" className="form-control"/>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-6">*/}
                                            {/*    <label htmlFor="">confirm password</label>*/}
                                            {/*    <input type="text" className="form-control"/>*/}
                                            {/*</div>*/}
                                            <div className="col-md-12">
                                                <label htmlFor="">address</label>
                                                <textarea name="" id="" cols="30" rows="6" name="address"
                                                          className="form-control"
                                                          onChange={e => this.address = e.target.value}></textarea>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="">Country</label>
                                                <input type="text" name="country"
                                                       onChange={e => this.country = e.target.value}
                                                       className="form-control" required/>
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor="">city</label>
                                                <input type="text" name="city"
                                                       onChange={e => this.city = e.target.value}
                                                       className="form-control" required/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">ZIP/POSTAL CODE</label>
                                                <input type="text" name="zip" onChange={e => this.zip = e.target.value}
                                                       className="form-control" required/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="">sTATE/pROVINCE</label>
                                                <input type="text" name="state"
                                                       onChange={e => this.country_state = e.target.value}
                                                       className="form-control" required/>
                                            </div>
                                            {/*<div className="col-md-12 checkbox">*/}
                                            {/*    <input type="checkbox" id="box-1"/>*/}
                                            {/*    <label htmlFor="box-1">Craete an account for later use</label>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-12 checkbox">*/}
                                            {/*    <input type="checkbox" id="box-2"/>*/}
                                            {/*    <label htmlFor="box-2">Ship to the same address mentioned above </label>*/}
                                            {/*</div>*/}
                                        </div>

                                        <hr/>

                                        <div className="order-summery">
                                            <h2 className="primary-heading text-center">Order Summary</h2>
                                            <div className="row bb-1">
                                                <div className="col-md-6">
                                                    <span>Subtotal</span>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    ${this.state.totalCartPrice??''}
                                                </div>
                                            </div>
                                            {/*<div className="row bb-1">*/}
                                            {/*    <div className="col-md-6">*/}
                                            {/*        <span>Shipping fee</span>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-md-6 text-right">*/}
                                            {/*        USD 5.00*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="row bb-1">*/}
                                            {/*    <div className="col-md-9">*/}
                                            {/*        <input type="text" className="form-control"*/}
                                            {/*               placeholder="Enter Voucher Code"/>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-md-3">*/}
                                            {/*        <button className="btn d-btn w-100 btn-brown-pattern">Apply</button>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="row bb-1">
                                                <div className="col-md-6">
                                                    <span>Flat Shipping</span>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                     ${ship_total??''}
                                                </div>
                                            </div>
                                            <div className="row bb-1">
                                                <div className="col-md-6">
                                                    <span>Total</span>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    ${this.state.totalCartPrice+ship_total??''}
                                                </div>
                                            </div>

                                            <div className="col-md-12 mt-4 p-0">
                                                <button  className="btn d-btn w-100 btn-brown-pattern"
                                                >proceed to
                                                    checkout
                                                </button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {

        cart: state.allCartProducts.cart
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearCart: () => dispatch(clearCart()),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
import {applyMiddleware, compose, createStore} from "redux";
import reducers from './reducers/Index';
import thunk from 'redux-thunk';


const middleware = [thunk];
const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors;
        alert(e);
    }
};

const persistedState = loadState();
//store create
const store=createStore(reducers,
    persistedState ,
    composeEnhancers(
        applyMiddleware(...middleware)


    )

);
// This is actually call every time when store saved
store.subscribe(() => {
    saveState(store.getState());
});


export default store;
import React from 'react';

const Section_one = () => {

    return (
        <React.Fragment>
            <section className="realunknown">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="overlay">
                                <div className="row no-gutters align-items-center">
                                    <div className="col-md-6">
                                        <div className="realhead">

                                            <p>
											{/*Real BOKS streamlines the closing gift process for Realtors and Brokers,
                                            freeing
                                            up valuable time.
                                            */}
											RealBOKS streamlines the closing gift process for Realtors and Brokers, freeing up your valuable time.
											</p>
                                            <p>
											{/* We create beautiful, functional and personalized closing gift boxes for
                                            both
                                            buyers and sellers. */}
											We create beautiful, functional and personalized closing gift boxes for buyers as well as sellers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src="images/new4.jpg" className="img-fluid" alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ourProsec">
                <div className="container">
                    <div className="proSechead">
                        <h2>Curated Product</h2>
                        <p> 
						{/* RealBOKS Collections, exclusively designed by our team. */}
						RealBOKS Collections exclusively designed by our team.
						</p>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-4">
                            <img src="images/img35.jpg" className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-4">
                            <div className="content">
                                <h4>Gorgeous packaging and a multi-stage reveal make the unboxing process a gift in itself.</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src="images/img36.jpg" className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-4">
                            <div className="content">
                                <h4>We have many items to choose from and are always adding new, innovative, useful products to our line.</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src="images/img37.jpg" className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-4">
                            <div className="content">
                                <h4>Our team of Creatives and Craftsmen strive to create the highest quality gifts for you and your clients.</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src="images/img38.jpg" className="img-fluid" alt="img" />
                        </div>
                        <div className="col-md-4">
                            <div className="content">
                                <h4>Choose a gift that leaves a lasting impression.</h4>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img src="images/img39.jpg" className="img-fluid" alt="img" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="featurProduc">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="overlay">
                                <div className="row align-items-center flex-row-reverse">
                                    <div className="col-md-6">
                                        <div className="realhead">
                                            <p>Whether ordering a curated box or customizing one, the process is quick
                                            and
                                            easy.
                                            </p>
                                            <p>Your BOKS can be delivered to you or sent to clients directly.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img src="images/update1.jpg" className="img-fluid" alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="proSechead">
                        <h2>Featured Vendor</h2>
                        <p>We are proud to partner with many independent manufactures and artisans</p>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 pr-0">
                            <div className="featurPro">
                                <figure>
                                    <a href="#">
                                        <img alt="img" className="img-fluid" src="images/img40.jpg" />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className="col-sm-6 pl-0">
                            <div className="featurPro">
                                <figure>
                                    <a href="#">
                                        <img alt="img" className="img-fluid" src="images/img41.jpg" />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="featurPro">
                                <figure>
                                    <a href="#">
                                        <img alt="img" className="img-fluid" src="images/img42.jpg" />
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="featurPro">
                                <figure>
                                    <a href="#">
                                        <img alt="img" className="img-fluid" src="images/img43.jpg" />
                                    </a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="regardngBox">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <h2>Gary Keller, co-founder of Keller Williams Realty</h2>
                            <p>"There are two things your gift must do: <br />It must have shelf life and be useful. Give a gift that is used daily and will last forever."</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="blog">
                <div className="container">
                    <div className="proSechead">
                        <h2>Latest News</h2>
                        <h5>OUR BLOG</h5>
                    </div>
                    <div className="proSechead">
                        <h2>Coming Soon</h2>

                    </div>
                    {/*<div className="row justify-content-center">*/}
                    {/*    <div className="col-md-4 col-sm-6">*/}
                    {/*        <div className="blogBox">*/}
                    {/*            <div className="blogImg">*/}
                    {/*                <img alt="img" className="img-fluid" src="images/img44.jpg" />*/}
                    {/*                <a href="#">*/}
                    {/*                    <i className="fal fa-eye"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="blogContent">*/}
                    {/*                <ul>*/}

                    {/*                    <li>DECEMBER 30, 2020</li>*/}
                    {/*                </ul>*/}
                    {/*                <h3 className="black">Blog</h3>*/}
                    {/*                <a className="themeBtn btn-1c chngbtn" href="#">read more</a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-4 col-sm-6">*/}
                    {/*        <div className="blogBox">*/}
                    {/*            <div className="blogImg">*/}
                    {/*                <img alt="img" className="img-fluid" src="images/img45.jpg" />*/}
                    {/*                <a href="#">*/}
                    {/*                    <i className="fal fa-eye"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="blogContent">*/}
                    {/*                <ul>*/}

                    {/*                    <li>DECEMBER 29, 2020</li>*/}
                    {/*                </ul>*/}
                    {/*                <h3 className="black">Blog</h3>*/}
                    {/*                <a className="themeBtn btn-1c chngbtn" href="#">read more</a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-4 col-sm-6">*/}
                    {/*        <div className="blogBox">*/}
                    {/*            <div className="blogImg">*/}
                    {/*                <img alt="img" className="img-fluid" src="images/img46.jpg" />*/}
                    {/*                <a href="#">*/}
                    {/*                    <i className="fal fa-eye"></i>*/}
                    {/*                </a>*/}
                    {/*            </div>*/}
                    {/*            <div className="blogContent">*/}
                    {/*                <ul>*/}

                    {/*                    <li>DECEMBER 29, 2020</li>*/}
                    {/*                </ul>*/}
                    {/*                <h3 className="black">Blog</h3>*/}
                    {/*                <a className="themeBtn btn-1c chngbtn" href="#">read more</a>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </section>


        </React.Fragment>
    );

}
export default Section_one;
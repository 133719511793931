import {combineReducers} from  'redux';
import productList from './ProductReducer';
import products from './AddToCartReducer';
import Corporate from './CorporateReducer';
import cart from './AddToCartReducer';
export default combineReducers({
    allCartProducts:products,
    allProducts: productList,

    // allCart:cart,



})

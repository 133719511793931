import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import cart from "../../reducers/AddToCartReducer";
import {adjustItemQty, productTotal, removeFromCart, setPackageName} from "../../actions/CartAction";
import connect from "react-redux/lib/connect/connect";
import axios from "axios";
import $ from 'jquery';
import * as linksType from "../../axios/ApiPath";

const AddToCartList = ({item, productTotal,adjustQty, removeFromCart, cart,selection, packageData,setPackageName}) => {

    const [totalPrice, setTotalPrice] = useState(0);
    const [packages, setPackages] = useState(0);
    const [getTotalQty, settotalQty] = useState(0);
    const [packPrice, SetPackPrice] = useState(0);


    let textInput = React.createRef();

    useEffect(() => {


        let items = 0;
        let price = 0;
        let product_ids = 0;
        let packPrice=0;


        selection.forEach(item => {
            items += item.qty;
            price += item.qty * item.product_current_price;

            product_ids = item.id;
        })
        packPrice = items <= 6 ? 40 : items >= 7 ? 55 : 0;
        settotalQty(items);


        if(price) {
            setTotalPrice(price + packPrice);
            setPackages(packageData)
            addToCartDb(product_ids)


            productTotal(price + packPrice);
            setPackageName(packPrice)
        }else{
            setTotalPrice(0);
        }
    }, [selection, totalPrice, setTotalPrice,setPackageName])


    function addToCartDb(id) {

        const response = axios.post(linksType.Local_API_PATH+'add-to-cart/' + id).then((res) => {

        }).catch((err) => {
            console.log(err);

        })
    }


    function AddQty (e,product_id,ProductQty){
        let addQty=1;

        addQty += ProductQty;
        adjustQty(product_id,addQty)

    }
    function SubtractQty (e,product_id,ProductQty){
        let addQty=0;

        addQty = ProductQty;
        addQty--;
        if(addQty>=1) {
            adjustQty(product_id, addQty)
        }

    }


    // try {
    //     const serialisedState = localStorage.getItem("reduxState");
    //     if (serialisedState === null) console.log( undefined);
    //     var d=JSON.parse(serialisedState);
    //         d.allCartProducts.cart;
    // } catch (e) {
    //     console.warn(e);
    //
    // }


//    console.log(a.allCart.cart)
    const selectionlist = selection.map((product) => {
        return (


            <li className="selectedItem" key={product.id}>
                <img src={linksType.Local_Image_Path +"products/"+ product.product_image}
                     className="img-fluid"
                     alt="img"/>
                <a href="javascript:void(0)" className="remove" onClick={() => removeFromCart(product.id)}>x</a>
                <div className="proCounter">
                    <span onClick={(e)=>SubtractQty(e,product.id,product.qty)} className="minus">-</span>
                    <input ref={textInput} min="1" readOnly
                           type="number"  value={product.qty}
                    />
                    {/*onClick={(e)=>sayHello(e) }*/}
                    <span onClick={(e)=>AddQty(e,product.id,product.qty)} className="plus">+</span>
                </div>
            </li>


        );
    })
    const siderBarCarlist = selection.map((productlistside) => {

        return (
            <React.Fragment>

                <ul >
                    <li>{productlistside.qty}</li>
                    <li>{productlistside.product_name}</li>
                    <li>${productlistside.product_current_price * productlistside.qty}</li>
                </ul>


            </React.Fragment>
        )
    })
    var getPack = localStorage.getItem('pack')
    getPack = JSON.parse(getPack)

    var packageList = null
    if (!getPack == 0) {
        packageList = getPack.map((packageData) => {
// console.log("qty", getTotalQty);
            return (
                <li className={packageData.package_name == "Core" && getTotalQty <= 6 ? 'active' : packageData.package_name == "Premium" && (getTotalQty >=7) ? 'active' : packageData.package_name == "Custom" && totalPrice >= packageData.package_price ? 'active' : ''}>{packageData.package_name}</li>

            )
        })
    }


    return (
        <React.Fragment>
            <div className="form-card">
                <div className="formMini">
                    <ul>


                        {packageList ?? ''}


                    </ul>
                </div>
                <div className="row no-gutters">
                    <div className="col-md-7">
                        <div className="selectItem">
                            <span>Start Selecting Items To Fill Your BOKS</span>
                            <ul>
                                {selectionlist}
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-5">
                        <div className="boxContent">

                            <h2>BOKS Contents</h2>
                            {siderBarCarlist}
                            <span>Current BOKS Size | {getTotalQty <= 6 ?'Core':getTotalQty >=7   ?'Premium':'' }</span>

                            <h6> {getTotalQty <= 6 || packPrice!=0 ?'Core BOKS Price ':getTotalQty >=7   ?'Premium BOKS Price':'' }: {getTotalQty <= 6 || packPrice!=0 ?'$40':getTotalQty >=7   ?'$55':'$0' }</h6>
                            <h6>BOKS Subtotal: ${totalPrice}</h6>
                            {/*<button  className="btn d-btn btn-brown-pattern ">Complete Box</button>*/}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

const mapDispatchToProps = (dispatch) => {
    return {
        adjustQty: (id, value) => dispatch(adjustItemQty(id, value)),
        productTotal:(total)=>dispatch(productTotal(total)),
        setPackageName: (id) => dispatch(setPackageName(id)),
        removeFromCart: (id) => dispatch(removeFromCart(id)),
    };
};
const mapStateToProps = (state) => {
    return {
        selection: state.allCartProducts.selection,
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddToCartList)

import React, {useEffect} from 'react';
import Footer from "../../component/include/Footer";
import Header from "../../component/include/Header";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {Local_API_PATH} from "../../axios/ApiPath";
import connect from "react-redux/lib/connect/connect";
import {useSelector} from "react-redux";
import * as linksType from "../../axios/ApiPath";
import {Link} from 'react-router-dom';
import {loadCorporateProduct} from "../../actions/CartAction";
import {Redirect} from 'react-router-dom';
import Helmet from "react-helmet";
import ReactModal from 'react-modal';

class ProductDetailTest extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            showModal: this.props.showModal || false,
            products: this.props.product,


        };

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

    }

    componentDidMount() {
        document.addEventListener('keyup', function (e) {
            console.log(e.key)
            if (e.key == "Escape") {
                this.setState({showModal: false})
            }
        }.bind(this))

    }

    handleOpenModal() {


        var script = document.createElement('script')
        script.src = '/js/modal.min.js';
        script.async = true;
        document.body.appendChild(script);

        this.setState({showModal: true});

    }

    handleCloseModal() {


        this.setState({showModal: false});


    }


    render() {

        var multiImg = 1;

        multiImg = this.state.products.product_images.map((mult) => {


            return (
                // <div>
                //     <figure>
                <img src={linksType.Local_Image_Path + "products/" + mult.product_images} className="img-fluid"
                     alt=""
                />
                //     </figure>
                // </div>
            )


        })
        if (multiImg == false) {
            multiImg = (


                // <div>
                //     <figure>
                <img src={linksType.Local_Image_Path + "products/" + this.state.products.product_image}
                     className="img-fluid"
                     alt=""
                />
                //     </figure>
                // </div>


            )
        }
        return (
            // related_products = getProducts.mightAlsoLike.map((items) => {
            //
            <React.Fragment>

                <li>
                    <a href="javascript:void(0)" onClick={this.handleOpenModal}>
                        <img
                            src={linksType.Local_Image_Path + "products/" + (typeof this.state.products != 'undefined' ? this.state.products.product_image : '')}/>
                    </a>

                    <div className="details_specs">
                        <h6>{this.state.products ? this.state.products.product_name : ''}</h6>
                        <p dangerouslySetInnerHTML={{__html: this.state.products.description.substr(0, 150) +'...' }}></p>
                    </div>
                </li>


                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example">
                    <a className="modal-close" onClick={(e) => this.handleCloseModal()}>Close </a>

                    <div className="row">

                        <div className="col-md-6">

                            <div className="single-items">

                                {multiImg ?? ''}


                            </div>
                            <div className="single-item-navs">
                                {multiImg ?? ""}
                            </div>
                        </div>


                        <div className="col-md-6 text-center">
                            <div className="product-modal product-modal12">
                                <div id="productModal">
                                    <h2>{this.state.products.product_name ?? ''}</h2>
                                    <p dangerouslySetInnerHTML={{__html: this.state.products.description ?? ''}}></p>
                                </div>
                            </div>
                        </div>

                    </div>

                </ReactModal>
            </React.Fragment>
        )
    }

}

export default ProductDetailTest;
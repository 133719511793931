import React, {useEffect, useState} from 'react';
import Footer from "../component/include/Footer";
import Header from "../component/include/Header";
import Helmet from "react-helmet";
import {Link, NavLink} from 'react-router-dom';


const Faqs=() => {
    return(
        <React.Fragment>
            <Header/>
            <Helmet>
                <title>RealBOKS | FAQ</title>
            </Helmet>
        <section className="banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2>Frequently Asked Questions</h2>
                        <ul>
                            <li><Link to='/'>HOME</Link></li>
                            <li><span>FAQ</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    <section className="faqMain">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="faqHead">
                        <div className="faq-lst">
                            <div id="accordion">
                                <div className="card">
                                    <div id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                <i className="fal fa-angle-down"></i>
                                                How long for my order to ship?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordion">
                                        <div className="card-body">Your BOKS will ship within 72 hours. Shipping time
                                            will vary based on location. West coast shipping time is currently 3-5 days.
                                            Midwest shipping is 2-4 days. East coast shipping times is 1-3 days. LuxBOKS
                                            orders shipping time will be determined at time of order placement.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                <i className="fal fa-angle-down"></i>
                                                How will my order ship?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            All Ready-To-Ship and Build-Your-BOKS orders are carefully packaged in
                                            custom designed shipping boxes to ensure your BOKS arrives in perfect
                                            condition. We use eco-friendly packaging materials as much as possible. No
                                            peanuts or plastic bubble wrap is used.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false"
                                                    aria-controls="collapseThree">
                                                <i className="fal fa-angle-down"></i>
                                                What do I do if my package arrives damaged?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            Call us immediately! We will expedite another BOKS to you within 24 hours
                                            Monday - Friday. We will send you a call tag to have the damaged BOKS
                                            returned to us. Making sure the customer is taken care of is our top
                                            priority.
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div id="headingfour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapsefour" aria-expanded="false"
                                                    aria-controls="collapsefour">
                                                <i className="fal fa-angle-down"></i>
                                                Do you ship internationally?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapsefour" className="collapse" aria-labelledby="collapsefour"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            At this time, we only ship within the United States.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingfive">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapsefive" aria-expanded="false"
                                                    aria-controls="collapsefive">
                                                <i className="fal fa-angle-down"></i>
                                                Will pricing or a receipt showing pricing included in the gift?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapsefive" className="collapse" aria-labelledby="collapsefive"
                                         data-parent="#accordion">
                                        <div className="card-body">No. Our transaction is for our customers only. We
                                            will email your receipt directly to you.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingsix">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapsesix" aria-expanded="false"
                                                    aria-controls="collapsesix">
                                                <i className="fal fa-angle-down"></i>
                                                What is your return policy?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapsesix" className="collapse" aria-labelledby="collapsesix"
                                         data-parent="#accordion">
                                        <div className="card-body">If for any reason you are unsatisfied with your
                                            RealBOKS please contact us at 859-588-8955. We are more than happy to find a
                                            solution to correct the situation. Sometimes real estate deals fall through
                                            in the last minute. We will work with you on an individual basis regarding
                                            refunds or exchanges. We are so sure that both you and your client will love
                                            their gift, we stand behind our product 100%. If your BOKS is damaged in
                                            shipping, we will replace it immediately. (We will facilitate the returning
                                            of the damaged BOKS).
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingseven">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseseven" aria-expanded="false"
                                                    aria-controls="collapseseven">
                                                <i className="fal fa-angle-down"></i>
                                                Can I have my BOKS top engraved with something other than what is shown
                                                on your site?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseseven" className="collapse" aria-labelledby="collapseseven"
                                         data-parent="#accordion">
                                        <div className="card-body">Yes! Any custom designing will fall under the LuxBOKS
                                            category. We are here to create the PERFECT gift for your clients. Feel free
                                            to give us a call or email us any time.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingeight">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseeight" aria-expanded="false"
                                                    aria-controls="collapseeight">
                                                <i className="fal fa-angle-down"></i>
                                                What if I would like to add something personal to the BOKS prior to
                                                giving it to my client?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseeight" className="collapse" aria-labelledby="collapseeight"
                                         data-parent="#accordion">
                                        <div className="card-body">If that item is something we can procure for you, we
                                            would be more than happy to. If it is something you already possess, then
                                            adding it to your BOKS is simple. Once you receive your BOKS, simply open
                                            the outer shipping container, remove your BOKS top, and add your personal
                                            gift. We intentionally do not seal the lids with ribbon etc. or wrap our
                                            gifts with tissue paper.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingnine">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapsenine" aria-expanded="false"
                                                    aria-controls="collapsenine">
                                                <i className="fal fa-angle-down"></i>
                                                If I requested a greeting card without a message, where will I find the
                                                greeting card?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapsenine" className="collapse" aria-labelledby="collapsenine"
                                         data-parent="#accordion">
                                        <div className="card-body">All greeting cards are placed in their unsealed
                                            envelope on TOP of the BOKS for shipping. This will allow you to write a
                                            personal message etc. When you are satisfied with your card, simply lift the
                                            BOKS top, and place the card inside for presenting to your client. Or leave
                                            it on top, whichever you prefer.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingten">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseten" aria-expanded="false"
                                                    aria-controls="collapseten">
                                                <i className="fal fa-angle-down"></i>
                                                How can I make my BOKS more of a branded experience?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseten" className="collapse" aria-labelledby="collapseten"
                                         data-parent="#accordion">
                                        <div className="card-body">A fabulous alternative to directly including your
                                            logo, is the addition of your agency’s colors within the box. Any place we
                                            use ribbon, we can exchange that for ones in your color. It adds a pop of
                                            color to the box and represents you and your company, without being too over
                                            the top. This option is part our LuxBOKS service. Please feel free to call
                                            or email us anytime. We are here to help facilitate a positive gifting
                                            experience for you!
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingeleven">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseeleven" aria-expanded="false"
                                                    aria-controls="collapseeleven">
                                                <i className="fal fa-angle-down"></i>
                                                Can I customize my BOKS with my Logo?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseeleven" className="collapse" aria-labelledby="collapseeleven"
                                         data-parent="#accordion">
                                        <div className="card-body">We do not recommend adding your actual logo to the
                                            BOKS or to any of the products inside. This has been shown to diminish the
                                            impact of a closing gifts. According to research, clients often feel it is
                                            more direct marketing and less about actual gratitude. Leaving your clients
                                            feeling appreciated and valued is the purpose of gift giving. We do offer
                                            beautiful quote boards that can be included in your BOKS. We attach a holder
                                            for your business card on the back.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div id="headingtwelve">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapsetwelve" aria-expanded="false"
                                                    aria-controls="collapsetwelve">
                                                <i className="fal fa-angle-down"></i>
                                                Do you offer annual client closing day anniversary gifts?
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapsetwelve" className="collapse" aria-labelledby="collapsetwelve"
                                         data-parent="#accordion">
                                        <div className="card-body">We will be introducing our Closing Day anniversary
                                            gift line, Holiday gift line and open house gifts in September 2021.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5 cntctDtls">
                <div className="col-md-12">
                    <p>If you have any questions, please feel free to call us or email us your questions. We will
                        respond to all inquires as quickly as possible!</p>
                    <a href="mailto:info@realboks.com">info@realboks.com</a>
                    <a href="tel:8595888955">859-588-8955</a>
                </div>
            </div>
        </div>
    </section>
            <Footer/>
        </React.Fragment>

    )
}
export default Faqs;
import React from 'react';
import Header from "../../component/include/Header";
import Footer from "../../component/include/Footer";
import Helmet from "react-helmet";

const LuxBox = () => {

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>RealBOKS | LuxBOKS</title>
            </Helmet>
            <div className="main-slider">
                <div className="carousel slide carousel-fade" data-interval="8000" data-ride="carousel" id="carouselExampleControls">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img alt="First slide" className="img-fluid w-100" src="images/servicebanner.jpg" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="slide-txt textupdated">
                                <h3 className="wow fadeInUp" data-wow-delay="1.2s">
                                            The LuxBOKS
                                </h3>
                                        <h4 className="wow fadeInDown" data-wow-delay="1s">Concierge Service</h4>
                                        <p className="wow fadeInLeft" data-wow-delay="1.3s">Let  our experts handle the job
                                        so you can stay focused on yours!
                                </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="stayConected stayHome Serviceadded">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="newtext">
                                <div className="proSechead">
                                    <p> 
Our “Ready to Ship” and “Build Your BOKS” options are splendid. However, if you are interested in a specific item(s), we are eager to assist. Our Concierge Service is here to help streamline the gifting process. It makes it easier for our clients to say "Thank You" to those who matter the most.
                            </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="communicationSec">
                <div className="container">
                    <div className="row align-items-center mt-50">
                        <div className="col-md-4">
                            <div className="communicationleft">
                                <figure>
                                    <img src="images/gethring.jpg" alt="" className="w-100" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="communicationright">
                                <h4>COMMUNICATION</h4>
                                <p>
We will hear all about your ideas and come up with a plan and a budget to get started. If you only have a basic idea of what you are looking for, the concierge can help flesh out that concept and create a fabulous gifting experience. 
 </p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-50">
                        <div className="col-md-4">
                            <div className="communicationleft">
                                <figure>
                                    <img src="images/cart.jpg" alt="" className="w-100" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="communicationright">
                                <h4>PROCUREMENT</h4>
                                <p>
                                   We will source and purchase your gift(s) for the best possible price.
								</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-50">
                        <div className="col-md-4">
                            <div className="communicationleft ">
                                <figure>
                                    <img src="images/updatedlogo.png" alt="" className="w-100" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="communicationright">
                                <h4>presentation</h4>
                                <p>
                                Just like curb appeal is to a house, presentation is everything. Our craftsmen will build a one-of-a-kind unBOKSing experience. Our packaging specialist will beautifully wrap and secure your gift within the BOKS for maximum impact upon opening.    
								</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center mt-50">
                        <div className="col-md-4">
                            <div className="communicationleft ">
                                <figure>
                                    <img src="images/truck.jpg" alt="" className="w-100" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="communicationright">
                                <h4>Logistics</h4>
                                <p>
Over-sized items can be delivered directly to your client at a time that you set. We ensure that the package is free from any invoices or unnecessary details so that the essence of the gift remains alive for the recipient.
                        </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </React.Fragment>

    );
}
export default LuxBox;
import React from 'react';
import {Link} from 'react-router-dom';
import Header from "../../component/include/Header";
import Footer from "../../component/include/Footer";
const ThankYou = () => {

    return (
        <React.Fragment>
            <Header/>
            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                        <h1><b>Thank you For The Order</b></h1>
                    {/*<h4><span><Link to="/"> Home</Link></span></h4>*/}
                        </div>
                    </div>
                </div>
            </section>


            <Footer/>
        </React.Fragment>


    )
}
export default ThankYou;
import * as actionTypes from "../constrant/CartConst";
import {ActionTypes} from "../constrant/ProductsConst";
import axios from "axios";

import * as linksType from '../axios/ApiPath';
import {toast} from "react-toastify";
// import {dispatch} from "jest-circus";


export const loadProduct = (products) => {

    return {
        type: actionTypes.LOAD_PRODUCT,
        payload: products
    };

};
export const addToCart = (itemID) => {


    return {
        type: actionTypes.ADD_TO_CART,
        payload: {
            id: itemID,
        },
    };
};

// export const removeFromCart = (itemID) => {
//     return {
//         type: actionTypes.REMOVE_FROM_CART,
//         payload: {
//             id: itemID,
//         },
//     };
// };
export const removeFromCart = (itemID) => {
    return {
        type: actionTypes.REMOVE_FROM_SELECTION,
        payload: {
            id: itemID,

        },
    };
};


export const adjustItemQty = (itemID, qty) => {

    return {
        type: actionTypes.ADJUST_ITEM_QTY,
        payload: {
            id: itemID,
            qty,
        },
    };
};
export const adjustCartQty = (row_id, type, qty) => {

    return {
        type: actionTypes.ADJUST_ITEM_CART_QTY,
        payload: {
            id: row_id,
            qty,
            type: type
        },
    };
};

export const loadCurrentItem = (item) => {
    return {
        type: actionTypes.LOAD_CURRENT_ITEM,
        payload: item,
    };
}

export const selectCards = (item,cardName) => {


    return {
        type: actionTypes.SELECT_CARDS,
            payload:{
                image:item,
                cardName:cardName
            }
    }
    // dispatch {
    //      type: actionTypes.SELECT_CARDS,
    //      payload: item,
    //  };
}
export const addCards = (form) => {



    return {
        type: actionTypes.ADD_CARD_FORM,
        payload: form
    }

}
export const addEngraving = (form) => {

    console.log(form)

    return {
        type: actionTypes.ADD_ENGRAVING_FORM,
        payload: form
    }

}
export const selectBoksTop = (item) => {


    return {
        type: actionTypes.SELECT_BOKS_TOP,
            payload: item
    }
    // dispatch {
    //      type: actionTypes.SELECT_CARDS,
    //      payload: item,
    //  };
}
export const addBoksTop = (form) => {

    console.log(form)

    return {
        type: actionTypes.ADD_BOKS_TOP_FORM,
        payload: form
    }

}


export const refreshCart = (item) => {


    return {
        type: actionTypes.REFRESH_SELECTION,
        payload: item
    }
};
export const productTotal = (total) => {


    return {
        type: actionTypes.PRODUCT_TOTAL,
        payload: total
    }

};

export const setPackageName = (name) => {


    return {
        type: actionTypes.Set_Package_Name,
        payload: name
    }

};
export const removeFromRowCart = ( rowId) => {


    return {
        type: actionTypes.REMOVE_FROM_CART,
        payload: {
            // id: itemID,

            row_id: rowId
        },
    }
};
export const clearCart = () => {


    return {
        type: actionTypes.CLEAR_CART,

    }

};

export const loadCorporateProduct = (products) => {

    return {
        type: actionTypes.LOAD_CORPORATE_PRODUCTS,
        payload: products
    };

};

export const getSelectedProduct = (product) => {

    return {
        type: actionTypes.GET_SELECTED_PRODUCT,
        payload: product
    };

};

export const addToCorporateCart = (itemID,relatedProduct,itemQty) => {


    return {
        type: actionTypes.ADD_TO_CORPORATE_CART,
        payload: {
            id: itemID,
            qty:itemQty,
            relatedProduct:relatedProduct
        },
    };
};

export const ForgetPass = (token,email) => {

    return {
        type: actionTypes.Forget,
        payload:{
            token:token,
            email:email

        }
    };

};



import {useSelector} from "react-redux";
import * as linksType from "../../axios/ApiPath";
import React, {Component} from 'react';
import connect from "react-redux/lib/connect/connect";
import {setProducts} from "../../actions/ProductAction";
import {addCards, addEngraving, loadProduct} from "../../actions/CartAction";
import {toast} from "react-toastify";

class CardToFrom extends Component {

    // const getCards = useSelector((state) => state.allCartProducts.chooseCart);

    constructor(props) {
        super(props)
        this.state = {
            to: '', froms: '', description: null, firstName: null, lastName: null, clientAddrss: null,
            Image: null
        }

    }

    // Form submitting logic, prevent default page refresh


    // Method causes to store all the values of the
    // input field in react state single method handle
    // input changes of all the input field using ES6
    // javascript feature computed property names


    // handleChangeTo = event => {
    //
    //     this.setState({ to: event.target.value });
    // };
    // handleChangeFrom = event => {
    //
    //     this.setState({ froms: event.target.value });
    // };
    // handleChangeDescription = event => {
    //
    //     this.setState({ description: event.target.value });
    // };

    AddForm = event => {
        event.preventDefault();
        // console.log(this.state);
        // if (this.props.addCards(this.state)) {
        //     toast.success('Add to Cart Successfully')
        // } else {
        //     toast.error('SomeThing went wrong')
        // }



        const formData = {

            to: this.to,
            froms: this.froms,
            description: this.description,

        }

        const EngravingformData = {

            firstName: this.firstName,
            lastName: this.lastName,
            clientAddrss: this.clientAddrss,

        }
        if (this.props.addCards(formData) && this.props.addEngraving(EngravingformData)) {
            toast.success('Add to Cart Successfully')
        } else {
            toast.error('SomeThing went wrong')
        }

    }

    render() {

        var Image = null;


        if (this.props.getCards.image != undefined) {

            Image = this.props.getCards.image

        }
        if (typeof this.props.getCards[0] !== 'undefined') {
            Image = this.props.getCards[0]
        }

        return (

            <div className="cardLines">

                <div className="card">
                    <h2><strong>Client Details </strong></h2>
                    <p>Choose the perfect card for the occasion from our selection of
                        exclusive
                        designs. Our team handwrites each and every note to keep your gifts
                        personal. (Plus, we have great handwriting.)</p>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="changeCard">
                                    <img src={linksType.Local_Image_Path + 'cards/' + Image} className="img-fluid"
                                         alt="img"/>
                                    {/*<a href="#">(Change Card) <input type="file"/> </a>*/}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="formSet">
                                    <div className="row">

                                            <div className="col-sm-6">
                                                <label>To</label>
                                                <input type="text" name="to" placeholder="To"
                                                       onChange={e => this.to = e.target.value}/>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>From</label>
                                                <input type="text" name="froms" placeholder="From"
                                                         onChange={e => this.froms = e.target.value}/>
                                            </div>
                                            <div className="col-sm-12">
                                                <label>Message on Card</label>
                                                <textarea name="description"
                                                          onChange={e => this.description = e.target.value}
                                                          placeholder="Enter your card messeage here. 120 characters max."></textarea>
                                                <span>120 characters remaining</span>
                                            </div>
                                            <div className="col-md-12 my-4 clntHead">
                                                <h3>Client Details for Engraving</h3>
                                            </div>
                                            {/*<div className="col-sm-6">*/}
                                            {/*    <label>Build your BOkS </label>*/}
                                            {/*    <input type="text" name="fname" placeholder="Build your BOkS" onChange={e => this.firstName = e.target.value}/>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-sm-6">*/}
                                            {/*    <label>Name 2</label>*/}
                                            {/*    <input type="text" name="lname" placeholder="Name 2" onChange={e => this.lastName = e.target.value}/>*/}
                                            {/*</div>*/}
                                            <div className="col-sm-12">
                                                <label> Client Name(s) and Client Address </label>
                                                <textarea name="addr" placeholder="Client Name(s) and Client Address "  onChange={e => this.clientAddrss = e.target.value}></textarea>

                                                <button onClick={(e) => this.AddForm(e)}
                                                        className="action-button btn d-btn btn-brown-pattern w-100">Add
                                                    to Cart
                                                </button>

                                            </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        getCards: state.allCartProducts.chooseCart,

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addCards: (form) => dispatch(addCards(form)),
        addEngraving: (form) => dispatch(addEngraving(form)),



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CardToFrom);
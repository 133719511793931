import {ActionTypes} from '../constrant/ProductsConst';
import {useSelector} from "react-redux";
const initialState = {
    productList:[]

}


export default  (state = initialState, action) => {

    switch (action.type) {

        case ActionTypes.SET_PRODUCTS:

            return {

                ...state,
                productList:action.payload,

            };

        default:
            return state;
    }
}


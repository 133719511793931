import React, {useEffect} from 'react';
import Footer from "../../component/include/Footer";
import Header from "../../component/include/Header";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {Local_API_PATH} from "../../axios/ApiPath";
import connect from "react-redux/lib/connect/connect";
import {useSelector} from "react-redux";
import * as linksType from "../../axios/ApiPath";
import {Link} from 'react-router-dom';
import {loadCorporateProduct} from "../../actions/CartAction";
import { Redirect } from 'react-router-dom';
import Helmet from "react-helmet";

const Products = ({loadCorporateProduct}) => {
    const data = useSelector((state) => state.allCartProducts.CorporateSelectionProducts);

    var tokenexpire=false;
    useEffect(() => {
        window.scrollTo(0, 0)
        let token=localStorage.getItem('token');

        const response = axios.get(Local_API_PATH + 'getCorporateProduct',
            {
                headers: {"Authorization" : `Bearer ${token}`}
            }).then((res) => {

            loadCorporateProduct(res.data);

        }).catch((err) => {
            if(err.response.status==401){
                tokenexpire=true;
                window.location.href = "/logout"

            }
            else{
                window.location.href = "/logout"
            }
        })
    }, [loadCorporateProduct])



    const ProductList = data.map((item) => {
        return (
            <div className="col-lg-3 col-md-4 col-sm-6" key={item.id}>
                <div className="producBox">
                    <Link to={"product-detail/"+item.id}>
                        <figure><img src={linksType.Local_Image_Path +"products/"+item.product_image} className="img-fluid" alt="img"/></figure>
                        <h2>{item.product_name ?? ''}</h2>
                        <p>{item.product_title ?? ''}</p>
                        <h5>${item.product_current_price?? ''}</h5>
                    </Link>
                </div>
            </div>
        )
    })
    return (
        <React.Fragment>
            <Header/>
            <Helmet>
                <title>RealBoks | Ready To Ship</title>
            </Helmet>
            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>The BOKS Collection</h2>
                            <p className="productSubtitle">Ready to Ship</p><br/>
                            <ul>
                                <li><Link to="/">HOME</Link></li>
                                <li><span>Ready To Ship</span></li>

                            </ul>
                            <p>
							{/*Choose from our selection of thoughtfully curated Closing Gift boxes which are sure to create a positive gifting experience for you and your client. They include premium Excelsior filler, greeting card and thoughtfully packaged products. All handcrafted keepsake boxes come standard with lid.*/}
Choose from our selection of thoughtfully curated closing gift boxes which are sure to create a positive gifting experience for you and your client. They include premium excelsior filler, a greeting card, and thoughtfully packaged products. All handcrafted keepsake boxes come standard with the lid.							
							<br/><br/>
							{/* We offer a variety of lid engraving options. Click on your favorite BOKS to see details and add your engraving and note card options. */}
							We offer a variety of lid engraving options. Click on your favorite BOKS to see details and add your engraving and note card options.

                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="ourProsec corporateGift">
                <div className="container">
                    <div className="row">

                        {ProductList}
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        loadCorporateProduct: (items) => dispatch(loadCorporateProduct(items)),


    };
};

export default connect(null, mapDispatchToProps)(Products)
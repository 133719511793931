import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import $ from 'jquery';
import connect from "react-redux/lib/connect/connect";

class ResetPassword extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            password: null,
            cnf_password: null,
            email: null,
            msg: null,
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        var fewSeconds = 8;
        // Ajax request
        var btn = $("#btn");
        btn.prop('disabled', true);
        setTimeout(function () {
            btn.prop('disabled', false);
        }, fewSeconds * 1000);
        // if(this.props.randToken.token)


        if(this.props.randToken.token===this.props.match.params.id) {
            const forgetPasswordEmail = {

                password: this.password,
                cnf_password: this.cnf_password,
                email:this.props.randToken.email,
            }

            // const forgetPass = JSON.stringify(forgetPasswordEmail);
            const cardResponse = axios.post(linksType.Local_API_PATH + 'reset-password', forgetPasswordEmail
            ).then((res) => {
                console.log(res.data)
                if(res.status===201){

                    this.setState({

                        password: res.data.password??null,
                        cnf_password:  res.data.cnf_password??null,
                    })
                }else{

                    const {history} = this.props;
                    history.push("/login")
                }

            }).catch((err) => {
                console.log(err);
            });


        }else{
            this.setState({
                msg:'SomeThing Went Wrong'
            })
        }


    }

    render() {
        console.log(this.props.randToken.token)
        return (
            <React.Fragment>
                <title>Real Boks</title>

                <body>


                <section className="creatAccount loginAccount">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center no-gutters">

                            <div className="col-md-6">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="createInput">
                                        <Link to="/"><img src="../images/logo.png" className="img-fluid"
                                                          alt="img"/></Link>
                                        <h2>reset password </h2>
                                        <div className="grpInpt">
                                            <label>New Password</label>
                                            <input type="password" required
                                                   onChange={e => this.password = e.target.value}/>
                                            <br/>
                                            <span className='text-danger'>{this.state.password??''}</span>
                                        </div>

                                        <div className="grpInpt">
                                            <label>Retype New Password</label>
                                            <input type="password" required
                                                   onChange={e => this.cnf_password = e.target.value}/>
                                                   <br/>
                                                       <span className='text-danger'>{this.state.cnf_password??''}</span>
                                        </div>

                                        <span className='text-danger'>{this.state.msg??''}</span>
                                        <div className="sgnBtn">
                                            <button  className="themeBtn btn-1c">reset password </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <img src="../images/img47.jpg" className="img-fluid" alt="img"/>
                            </div>

                        </div>
                    </div>
                </section>
                </body>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        randToken: state.allCartProducts.randToken
    }
}

export default connect(mapStateToProps)(ResetPassword);
//for live
// export const Local_API_PATH = "http://localhost:8000/api/";
export const Local_API_PATH = "https://realboks.thesupportonline.net/realboks/public/api/";

// for localhost
// https://realboks.thesupportonline.net/realboks/public/api/
// export const Local_API_PATH = "http://localhost/realboks/public/api/";


//image path
// export const Local_Image_Path="http://localhost/realboks/public/uploads/";

// imageLivepath
// https://realboks.thesupportonline.net/realboks/public/uploads/
// export const Local_Image_Path="http://localhost:8000/uploads/";
export const Local_Image_Path="https://realboks.thesupportonline.net/realboks/public/uploads/";
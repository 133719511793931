import React from 'react';
import logo from '../images/slidebg1.jpg';
import {Link} from 'react-router-dom';
const Silder = () => {
    return (

        <div className="main-slider">
            <div className="carousel slide carousel-fade" data-interval="8000" data-ride="carousel"
                 id="carouselExampleControls">

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img alt="First slide" className="img-fluid w-100" src="../images/slidebg1.jpg"/>
                        <div className="carousel-caption">
                            <div className="container-fluid">
								<div class="row justify-content-center">
									<div class="col-lg-10">
										<div className="slide-txt">

											<h4 className="wow fadeInDown" data-wow-delay="1s">Welcome To</h4>
											<h3 className="wow fadeInUp" data-wow-delay="1.2s">
												Real
												<strong>BOKS</strong>
											</h3>
											<p className="wow fadeInLeft" data-wow-delay="1.3s">Closing gifts curated exclusively
												for
											</p>
											<p className="wow fadeInLeft" data-wow-delay="1.5s">Real Estate Agents & Brokers</p>

											<Link className="themeBtn wow fadeInLeft btn d-btn btn-brown-pattern" data-wow-delay="2.4s"
											   to="/products">Enter
											</Link>
										</div>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        );



}
export default Silder;
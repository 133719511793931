import React from 'react';
import axios from "axios";
import * as linksType from "../../axios/ApiPath";

class Footer extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            email: null,

        }
    }

    handleSubmit = e => {
        e.preventDefault()
        const newsletter = {

            email: this.email,
        }

        const cardResponse = axios.post(linksType.Local_API_PATH + 'newsletter', newsletter
        ).then((res) => {
            console.log(res.data)
            if (res.data == 'success') {
                this.setState({
                    msg: 'You Have Successfully Subscribed To The newsletter'

                })


                //localStorage.setItem('random',res.data.random)
            } else {
                this.setState({
                    msg: res.data
                })

            }


        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        return (
            <React.Fragment>

                <section className="stayConected">
                    <div className="container">
                        <div className="proSechead">
                            <h2>Let’s Stay Connected</h2>
                            <p>Sign up today to our newsletter and receive <span>15% OFF</span> your first purchase,
                                and
                                also get
                                our sneak peeks, tips, and exclusive offers.</p>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="subscrbInput">
					<span>
						<input type="text" placeholder="Your e-mail" required
                               onChange={e => this.email = e.target.value}/>
						<button className="themeBtn btn-1c chngbtn btn-2">Subscribe</button>
					</span>
                                       <p className='text-success'> {this.state.msg??''}</p>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 wow fadeInLeft" data-wow-delay="0.5s">
                                <div className="copyRight">
                                    <p>Copryright © 2021, Real Boks | All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div id="search">
                    <button className="close" type="button">×</button>
                    <form>
                        <input placeholder="SEARCH" type="search" value=""/>
                        <div className="srch-btn">
                            <a href="search-result.php" className="themeBtn">Search</a>
                        </div>
                    </form>
                </div>

                <a id="back2Top" title="Back to top" href="#"><i className="fal fa-angle-up"></i></a>


                <div className="lognModal">
                    <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog"
                         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab"
                                               href="#home"
                                               role="tab"
                                               aria-controls="home" aria-selected="true"><i
                                                className="fas fa-unlock"></i>Login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab"
                                               href="#profile"
                                               role="tab"
                                               aria-controls="profile" aria-selected="false"><i
                                                className="fas fa-user-plus"></i>Register</a>
                                        </li>
                                    </ul>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="home" role="tabpanel"
                                             aria-labelledby="home-tab">
                                            <div className="loginForm">
                                                <input type="text" placeholder="Login *"/>
                                                <input type="text" placeholder="Password *"/>
                                                <div className="forget">
                                                    <a href="forgot-password.php" className="forgetContent">Forgot
                                                        password?</a>
                                                    <div className="agreCheck">
                                                        <input type="checkbox" id="vehicle1" name="vehicle1"
                                                               value="Bike"/>
                                                        <label htmlFor="vehicle1">Remember me</label>
                                                    </div>
                                                </div>
                                                <a href="#" className="themeBtn btn-1c">Login</a>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="profile" role="tabpanel"
                                             aria-labelledby="profile-tab">
                                            <div className="loginForm">
                                                <input type="text" placeholder="First Name"/>
                                                <input type="text" placeholder="Last Name"/>
                                                <input type="text" placeholder="Email"/>
                                                <input type="text" placeholder="Password *"/>
                                                <input type="text" placeholder="Confirm Password *"/>
                                                <a href="#" className="themeBtn btn-1c">Register</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        );
    }

}

export default Footer;
import Home from './pages/Home/Home';
import About from './pages/About/About';
import BuildBox from './pages/BuildBox/Buildbox';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Provider from "react-redux/lib/components/Provider";
import store from './Store';
import CartDetail from "./pages/CartView/CartDetail";
import React from "react";
import LoginFrom from "./pages/Auth/LoginFrom";
import RegisterForm from "./pages/Auth/RegisterForm";
import Checkout from "./pages/CartView/Checkout";
import ThankYou from "./pages/CartView/ThankYou";
import LuxBox from "./pages/LuxBox/LuxBox";
import Products from "./pages/Corporate/Products";
import Productdetail from "./pages/Corporate/Productdetail";
import Protected from './routes/Test';
import Logout from "./pages/Auth/Logout";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentMethod from "./pages/payment/PaymentMethod";
import ForgetPassword from "./pages/Auth/ForgetPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import ContactUs from "./pages/contact_us/ContactUs";
import Faqs from "./faqs/Faqs";
toast.configure()
function App() {
    // window.addEventListener("beforeunload", () => localStorage.removeItem('state'));

    return (

        <div className="App">
            <Provider store={store}>
            <Router>

                <Switch>
                    <Protected component={About} path="/about" exact />
                    <Protected path="/" exact component={Home}/>
                    <Protected path="/luxBOKS" exact component={LuxBox}/>
                    <Protected path="/BOKS" exact component={BuildBox}/>
                    <Protected path="/cart" exact component={CartDetail}/>
                    <Protected path="/checkout" exact component={Checkout}/>
                    <Protected path="/thankyou" exact component={ThankYou}/>
                    <Protected path="/products" exact component={Products}/>
                    <Protected path="/PaymentMethod" exact component={PaymentMethod}/>
                    <Protected path="/product-detail/:id" exact component={Productdetail}/>
                    <Protected path="/ContactUs" exact component={ContactUs}/>
                    <Protected path="/faqs" exact component={Faqs}/>
                    <Route component={LoginFrom} path="/login" exact/>
                    <Route component={Logout} path="/logout" exact />
                    <Route component={ForgetPassword} path="/ForgetPassword" exact />
                    <Route path="/ResetPassword/:id" exact component={ResetPassword}  />




                    <Route path="/register" exact component={RegisterForm}/>
                    {/*<Route path="/product/:productId"  exact component={Home}  />*/}



                </Switch>
            </Router>
            </Provider>
        </div>
    );

}

export default App;

import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import $ from 'jquery';
import connect from "react-redux/lib/connect/connect";
import {setProducts} from "../../actions/ProductAction";
import {ForgetPass, loadProduct, refreshCart} from "../../actions/CartAction";
class ForgetPassword extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            email:null,
            msg:null,
            randToken:null
        }
    }
    handleSubmit = e => {
        e.preventDefault()
        var fewSeconds = 8;
        // Ajax request
        var btn = $("#btn");
        btn.prop('disabled', true);
        setTimeout(function(){
            btn.prop('disabled', false);
        }, fewSeconds*1000);

        const forgetPasswordEmail = {

            email:this.email,
        }

        const forgetPass = JSON.stringify(forgetPasswordEmail);

        const cardResponse = axios.post(linksType.Local_API_PATH + 'forget-password', {

                formData:    forgetPass
            }
        ).then((res) => {
            console.log(res.data)
            if(res.data.success=='success'){
                this.setState({
                    msg:'Email Send Successfully',
                    randToken:res.data.random,
                })
                this.props.ForgetPass(res.data.random,res.data.email)

                //localStorage.setItem('random',res.data.random)
            }else{
                this.setState({
                    msg:res.data
                })
                var btn = $("#btn");
                btn.prop('disabled', false);
            }


        }).catch((err) => {
            console.log(err);
        });

    }

    render() {
        return (
            <React.Fragment>
                <title>Real Boks</title>

                <body>

                <section className="creatAccount loginAccount">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center no-gutters">
                            <div className="col-md-6">
                                <div className="createInput">
                                    <Link to="/"><img src="images/logo.png" className="img-fluid" alt="img"/></Link>
                                    <h2>Forgot password </h2>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="grpInpt">
                                            <label>Email</label>
                                            <input type="Email" required placeholder='Email' onChange={e => this.email = e.target.value}/>


                                        </div>
                                        <span className="text-danger">{this.state.msg??''}</span>

                                    <div className="sgnBtn">
                                        <button id="btn"  className="themeBtn btn-1c">Continue </button>
                                    </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src="images/img47.jpg" className="img-fluid" alt="img"/>
                            </div>
                        </div>
                    </div>
                </section>
                </body>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {


}
const mapDispatchToProps = (dispatch) => {
    return {
        ForgetPass: (token,email) => dispatch(ForgetPass(token,email)),



    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
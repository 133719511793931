import React, {useEffect, useState} from 'react';
import connect from "react-redux/lib/connect/connect";
import * as linksType from "../../axios/ApiPath";

const FooterLineProduct = ({selection, chooseBoksTop, counters}) => {

    const [totalPrice, setTotalPrice] = useState(0);
    const [getTotalQty, settotalQty] = useState(0);
    console.log(counters)

    useEffect(() => {



        //



        let items = 0;
        let price = 0;
        let product_ids = 0;
        let packPrice = 0;

        selection.forEach(item => {
            items += item.qty;
            price += item.qty * item.product_current_price;
            product_ids = item.id;
        })
        if (price) {
            let boksTop = 0;
            boksTop = chooseBoksTop.price || 0;
            packPrice = items <= 6 ? 40 : items >= 7 ? 55 : 0;
            setTotalPrice(price + boksTop + packPrice);
            settotalQty(items);
        } else {
            let boksTop = 0;
            packPrice = items <= 6 ? 40 : items >= 7 ? 55 : 0;
            setTotalPrice(price + boksTop + packPrice);
            settotalQty(items);
            setTotalPrice(0);

        }


    }, [selection, totalPrice, setTotalPrice, chooseBoksTop])

    const footerProductList = selection.map((product) => {
        return (

            <a href="#">
                <img src={linksType.Local_Image_Path + "products/" + product.product_image}/>
                <span>{product.qty ?? ''}</span>
            </a>

        )
    })



    return (

        <div className="bottom-list">
            <div>
                {footerProductList}
            </div>
            <div>
                <a className="themeBtn  btn d-btn btn-brown-pattern mr-2" href="#">Current BOKS Size
                    / {getTotalQty <= 6 ? 'CORE' : getTotalQty >= 7 ? 'Premium' : ''}</a>


                <button type="button" disabled={counters== false ? 'disabled' : ''}
                        className="action-button btn d-btn btn-brown-pattern">Complete BOKS
                    ${totalPrice}</button>

            </div>
        </div>
    )

}
const mapStateToProps = (state) => {
    return {
        selection: state.allCartProducts.selection,
        chooseBoksTop: state.allCartProducts.chooseBoksTop,
    };
};
export default connect(mapStateToProps)(FooterLineProduct)

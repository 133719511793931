import React, {Component} from 'react';
import Header from "../../component/include/Header";
import {Link} from "react-router-dom";
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import ForgetPassword from "./ForgetPassword";
import Helmet from "react-helmet";
import $ from 'jquery'
class LoginFrom extends Component  {
    constructor() {
        super();
        this.state = {
            email: null,
            password:null,
            errors:null
        }
        $(document).ready(function(){
        $('.creatAccount').parents('body').addClass('login-page')
        })
    }
    handleSubmit= event => {

        event.preventDefault();
        const formData = {

            email: this.email,
            password: this.password,


        }


        const cardResponse = axios.post(linksType.Local_API_PATH + 'login', formData
        ).then((res) => {
            console.log(res.data);
                if(res.status===201){

                this.setState({

                    email: res.data.email??null,
                    password: res.data.password??null,

                })

            }else if(res.data==='Unauthorised'){
                    this.setState({
                      errors:'Credential not match'
                    })
            }
                else {
                    const profile=JSON.stringify(res.data.profile)
                localStorage.setItem('token', res.data.success['token']);
                localStorage.setItem('user', profile)

                const {history} = this.props;
                history.push("/")
            }
        }).catch((err) => {
            console.log(err.data);
        });
    }

    render(){

    return (

        <React.Fragment>
            <Helmet>
                <title>RealBoks | Login</title>
            </Helmet>
            <body>
            <section className="creatAccount loginAccount">
                <div className="container-fluid p-0">
                    <div className="row justify-content-center align-items-center no-gutters">
                        <div className="col-md-6">
                            <div className="createInput">
                                <Link to={'/'}><img src="images/logo.png" className="img-fluid" alt="img"/></Link>
                                <h2>Login Account</h2>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="grpInpt">
                                        <label>Email</label>
                                        <input type="text" name="email"  onChange={e=>this.email=e.target.value}/>
                                        <p className=''>{this.state.email??''}</p>
                                    </div>
                                    <div className="grpInpt">
                                        <label>Password</label>
                                        <input type="password" name="password"   onChange={e=>this.password=e.target.value}/>
                                        <p className=''>{this.state.password??''}</p>
                                        <p>{this.state.errors??''}</p>
                                    </div>

                                    <p>{}</p>
                                    <div className="sgnBtn">
                                        {/*<a href="#" className="themeBtn btn-1c">Login</a>*/}
                                        <button  className="themeBtn btn-1c">Login</button>
                                        <div className="agreCheck12 agreCheck145">
							<span>
							{/*<input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>*/}
							{/*<label htmlFor="vehicle1">Remember me</label>*/}
							</span>
                                            <Link to="ForgetPassword" className="frgtPas">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <div className="newfield field-1">
                                        <p>Don't have an Account?</p>
                                        <Link to="/register">Register Now</Link>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <img src="images/new-11.jpg" className="img-fluid" alt="img"/>
                        </div>

                    </div>
                </div>
            </section>
            </body>
        </React.Fragment>


    )}
}
export default LoginFrom;
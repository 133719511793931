import Footer from "../../component/include/Footer";
import Header from "../../component/include/Header";
import React, {useEffect, useState} from "react"
import {useParams} from 'react-router-dom'
import axios from "axios";
import {Local_API_PATH} from "../../axios/ApiPath";

import connect from "react-redux/lib/connect/connect";
import {useSelector} from "react-redux";
import * as linksType from "../../axios/ApiPath";
import {addCards, addEngraving, addToCorporateCart, getSelectedProduct} from "../../actions/CartAction";
import CardsPopUp from "./CardsPopUp";
import BoksTopPopUp from "./BoksTopPopUp";
import {toast} from "react-toastify";
import {Link} from 'react-router-dom';
import Helmet from "react-helmet";
import ProductDetailPopUp from "../BuildBox/ProductDetailPopUp";
import ProductDetailTest from "./ProductDetailTest";

const Productdetail = ({getSelectedProduct, addToCorporateCart, chooseBoksTop, getCards, addEngraving, addCards}) => {

    const [qty, SetQty] = useState(1)
    const [scpt, Setscpt] = useState(1)
    //formdata

    const profileData = localStorage.getItem('user')
    var data = JSON.parse(profileData)
//card
    const [to, toUser] = useState("")
    const [froms, fromUser] = useState("")
    const [description, MessageUser] = useState("")
    //engraving
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [clientAddrss, setClientAddress] = useState("")

    const {id} = useParams()
    const getProducts = useSelector((state) => state.allCartProducts.ProductDetail);


    //form submit
    function handleSubmit(e, getProducts_id, mightAlsoLike, qty) {
        e.preventDefault();


        const formData = {
            user_id: data.id,
            to: to,
            froms: froms,
            description: description,

        }

        const EngravingformData = {
            user_id: data.id,
            firstName: firstName,
            lastName: lastName,
            clientAddrss: clientAddrss,

        }
        addEngraving(EngravingformData)
        // console.log(EngravingformData)

        addCards(formData)
        addToCorporateCart(getProducts_id, mightAlsoLike, qty)
        //     if(addCards(formData)){
        //     console.log(formData)
        //     // toast.success('Add to Cart Successfully')
        // }else{
        //     toast.error('SomeThing went wrong')
        // }
    }

    useEffect(() => {

        const response = axios.get(Local_API_PATH + 'getCorpProductDetail/' + id).then((res) => {
            var script = document.createElement('script')
            script.src = '/js/custom.min.js';
            script.async = true;
            document.body.appendChild(script);
            getSelectedProduct(res.data)

        }).catch((err) => {
            console.log(err);
        })


    }, [getSelectedProduct])

    //

    function AddQty(e, ProductQty) {
        let addQty = 1;

        addQty += ProductQty;
        SetQty(addQty)

    }

    function SubtractQty(e, ProductQty) {
        let addQty = 0;

        addQty = ProductQty;
        addQty--;
        SetQty(addQty)


    }

    //
    // }
    //
    function addToReadyToShip() {

    }

    let related_products = "";
    let multImage = '';
    let linksimg = '';
    if (typeof getProducts.mightAlsoLike != 'undefined') {
        if (getProducts.product_images) {
            multImage = getProducts.product_images.map((multimg) => {
                return (

                    <img src={linksType.Local_Image_Path + "products/" + multimg.product_images} className="img-fluid"
                         alt="img"/>

                )


            })

        }
         related_products = getProducts.mightAlsoLike.map((items) => {
             console.log( items.products)
             if (typeof items.products[0] != 'undefined') {
                 return (
                     <ProductDetailTest propsshowModal={true} product={items.products[0]}/>

                 );
             }
        })
        // related_products = getProducts.mightAlsoLike.map((items) => {
        //
        //     if (typeof items.products[0] != 'undefined') {
        //         return (
        //             <li>
        //                 <img
        //                     src={linksType.Local_Image_Path + "products/" + (typeof items.products[0] != 'undefined' ? items.products[0].product_image : '')}/>
        //                 <div className="details_specs">
        //                     <h6>{items.products[0] ? items.products[0].product_name : ''}</h6>
        //                     {/*<p dangerouslySetInnerHTML={{__html: items.products[0].description.substr(0, 150) +'...' }}></p>*/}
        //                 </div>
        //             </li>
        //         );
        //     }
        //
        // });

    }


    return (

        <React.Fragment>

            <Header/>
            <Helmet>
                <title>RealBoks | Ready To Ship</title>
            </Helmet>
            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{getProducts.product_name}</h2>
                            <ul>
                                <li><Link to="/">HOME</Link></li>
                                <li><Link to="/products">Ready To Ship</Link></li>
                                {/* <li><a href="#">SHOP</a></li> */}
                                {/* <li className="pr-2"><span>...</span></li> */}
                                <li><span>{getProducts.product_name}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="prodctDetail">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="detlImg">
                                <div className="single-item">

                                    {multImage ?? ''}
                                </div>
                                <div className="single-item-nav">
                                    {multImage ?? ''}
                                </div>

                                <div className="overlay">
                                    {/*<i className="far fa-search">*/}
                                    {/*{*/}

                                    {/*    linksimg = getProducts.product_images.map(linkdata => linkdata!=''?*/}
                                    {/*        (*/}

                                    {/*    <a data-fancybox="gallery"*/}
                                    {/*    href={linksType.Local_Image_Path + "products/" + linkdata.product_image}>*/}

                                    {/*    </a>):(<h3>ada</h3>))}*/}

                                    {/*}*/}
                                    {/*</i>*/}


                                </div>
                            </div>
                            <div className="prodtlContent">
                                <div className="pickcard">
                                    <a href="#" data-toggle="modal" data-target=".bd-example-modal-lg">Pick a card</a>
                                </div>
                                <div className="faximg">
                                    <img src={linksType.Local_Image_Path + "products/plac_real.png"}
                                         className="img-fluid selected_card_image" alt="img"/>
                                    <div className="faxContent">
                                        <h6 className="selected_card_name">Your Card</h6>
                                        {/*<a href="#">(change card)</a>*/}
                                    </div>
                                </div>
                                <div className="pickcard">
                                    <a href="#" data-toggle="modal" data-target=".boksTopPopUp">Pick Engraving</a>
                                </div>
                                <div className="faximg">
                                            <img src={linksType.Local_Image_Path + "products/plac_real.png"}
                                         className="img-fluid selected_top_image" alt="img"/>
                                    <div className="faxContent">
                                        <h6 className="selected_top_name">Your Engraving </h6>
                                        {/*<a href="#">(change card)</a>*/}
                                    </div>
                                </div>


                                <form className="toForm">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>To</label>
                                            <input type="text" placeholder="To" onChange={e => toUser(e.target.value)}
                                                   required/>
                                        </div>
                                        <div className="col-md-6">
                                            <label>From</label>
                                            <input type="text" placeholder="From"
                                                   onChange={e => fromUser(e.target.value)} required/>
                                        </div>
                                        <div className="col-md-12">
                                            <label>Message on card</label>
                                            <textarea placeholder="Enter your card message here. 180 characters max."
                                                      maxLength="180" spellCheck="false" required
                                                      onChange={e => MessageUser(e.target.value)}></textarea>

                                        </div>

                                        <div className="col-md-12 my-4 clntHead">
                                            <h3>Client Details for Engraving</h3>
                                        </div>
                                        {/*<div className="col-md-6">*/}

                                        {/*    <label>Name 1</label>*/}
                                        {/*    <input type="text" placeholder="Name 1" onChange={e => setFirstName(e.target.value)} />*/}
                                        {/*</div>*/}
                                        {/*<div className="col-md-6">*/}
                                        {/*    <label>Name 2</label>*/}
                                        {/*    <input type="text" placeholder="Name 2" onChange={e => setLastName(e.target.value)} />*/}

                                        {/* */}
                                        {/*</div>*/}
                            
                                        <div className="col-md-12">
                                            <label> Client Name(s) and Client Address </label>
                                            <textarea placeholder="Client Name(s) and Client Address"
                                                      onChange={e => setClientAddress(e.target.value)}></textarea>
                                        </div>
                                        {/*<button>Submit  </button>*/}
                                    </div>


                                    <div className="toForm">
                                        <button className="px-4"
                                                disabled={chooseBoksTop.length === 0 || getCards.length === 0 ? 'disabled' : !chooseBoksTop}
                                                onClick={(e) => {
                                                    handleSubmit(e, getProducts.id, getProducts.mightAlsoLike, qty)

                                                    toast.success('Add to Cart Successfully')
                                                }}>Add to Cart <span className='selected_price'
                                                                     data-price={getProducts.product_current_price}>. ${getProducts.product_current_price}</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="details-product">
                                <h4>
                                    {getProducts.product_name}
                                    <span>${getProducts.product_current_price}</span>
                                </h4>
                                <h2>Includes</h2>
                                <ul>
                                    {related_products}
                                </ul>

                                <div className="shop-details">
                                    {/* <input type="text" name="quantity" className="form-control input-number pl-0"
                                        value={qty ?? ''}
                                        min="1" max="100" /> */}
                                    {/* <span className="input-group-btn"> */}
                                    {/* <button type="button" onClick={(e) => AddQty(e, qty)} className="quantity-right-plus btn btn-number" data-type="plus" data-field="">
                                            <span className="glyphicon glyphicon-plus"><svg className="svg-inline--fa fa-angle-up fa-w-10"
                                                aria-hidden="true" data-prefix="fas"
                                                data-icon="angle-up" role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 320 512" data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z">
                                                </path>
                                            </svg>
                                            </span>
                                        </button> */}
                                    {/* <button type="button" onClick={(e) => SubtractQty(e, qty)} className="quantity-left-minus btn btn-number" data-type="minus" data-field="">
                                            <svg className="svg-inline--fa fa-angle-down fa-w-10" aria-hidden="true" data-prefix="fas"
                                                data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z">
                                                </path>
                                            </svg>

                                        </button> */}
                                    {/* </span> */}
                                    {/* <button className="themeBtn btn-1c" onClick={(e) => addToCorporateCart(getProducts.id, qty)} >
                                        Add To Cart
                                        <i className="far fa-shopping-bag"></i>
                                    </button> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/* <section className="descBox">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="adtnlBox">
                                <div className="adtnlTab">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        {/* <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                                role="tab" aria-controls="home" aria-selected="true">Description</a>
                                        </li> */}
            {/* <li className="nav-item">
                                            <a className="nav-link active" id="profile-tab" data-toggle="tab" href="#profile"
                                                role="tab" aria-controls="profile" aria-selected="false">Additional
                                                information</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact"
                                                role="tab" aria-controls="contact" aria-selected="false">Reviews (1)</a>
                                        </li>
                                    </ul>
                                </div> */}

            {/* <div className="tab-content" id="myTabContent"> */}
            {/* <div className="tab-pane fade show active" id="home" role="tabpanel"
                                        aria-labelledby="home-tab">
                                        <div className="descText">
                                            <p>


                                            </p>
                                        </div>
                                    </div> */}
            {/* <div className="tab-pane fade show active" id="profile" role="tabpanel"
                                        aria-labelledby="profile-tab">
                                        <div className="adtnlInfo">
                                            <h2 className="black">Additional information</h2>
                                            <ul>
                                                <li>color</li>
                                                <li>purple, red, yellow</li>
                                            </ul>

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="contact" role="tabpanel"
                                        aria-labelledby="contact-tab">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="userMain">
                                                    <h2 className="black">1 review for Product</h2>
                                                    <div className="userReview">
                                                        <img src="images/userimg.png" className="img-fluid" alt="img" />
                                                        <div className="reviewContent">
                                                            <h5>MARTHA STEWART – JULY 3, 2018</h5>
                                                            <ul>

                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="fas fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>

                                                            </ul>
                                                            <p>sample review</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="userMain">
                                                    <h2 className="black">Add a review</h2>
                                                    <p>Your email address will not be published. Required fields are
                                                    marked
                                                        *</p>
                                                    <div className="reviewForm">
                                                        <div className="formsetrvew">
                                                            <label>Name *</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="formsetrvew">
                                                            <label>Email *</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="agreCheck">
                                                            <input type="checkbox" id="vehicle1" name="vehicle1"
                                                                value="Bike" />
                                                            <label htmlFor="vehicle1">Save my name, email, and website
                                                            in
                                                                this browser for the next time I comment.</label>
                                                        </div>
                                                        <h6>Your rating *</h6>
                                                        <span>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="fas fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                        </span>
                                                        <label className="yreviw">Your review *</label>
                                                        <textarea></textarea>
                                                        <div className="agreCheck">
                                                            <input type="checkbox" id="vehicle1147" name="vehicle1147"
                                                                value="Bike" />
                                                            <label htmlFor="vehicle1147">By using this form you agree
                                                            with
                                                            the storage and handling of your data by this website.
                                                                *</label>
                                                        </div>
                                                        <a href="#" className="themeBtn btn-1c">submit</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            {/* </section> */}


            <div className="choseCard">
                <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                     aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <h2>Select a card for your BOKS</h2>
                            <p>Choose the perfect card for the occasion from our selection of exclusive designs. Our
                                team handwrites each and every note to keep your gifts personal. (Plus, we have great
                                handwriting.)</p>
                            <div className="row">
                                <CardsPopUp/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="choseCard">
                <div className="modal fade boksTopPopUp" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <h2>Engraving</h2>

                            {/*<p>*/}
							{/*Choose the perfect card for the occasion from our selection of exclusive designs. Our team handwrites each and every note to keep your gifts personal. (Plus, we have great handwriting.)*/}
							{/*</p>*/}

                            <p>
                                All gifts come standard in the ORIGINAL RealBOKS available in two sizes: Our Core<br/> BOKS 9x12x6” and our Premium BOKS 12x20x6”. The size is automatically determined <br/>by number of products chosen to be included…up to 15 products. All engraving option<br/> are available on both the Core and premium BOKS. Custom BOKS sizes and <br/> engravings are available with Lux BOKS option.
                                <br/><br/>
                                Please contact us to further discuss custom  options to perfectly fit your  needs
                            </p>
                            <div className="row">
                                <BoksTopPopUp/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer/>
        </React.Fragment>

    );

}
const mapStateToProps = (state) => {
    return {
        getCards: state.allCartProducts.chooseCart,
        chooseBoksTop: state.allCartProducts.chooseBoksTop,
        // productTotalPrice:state.allCartProducts.productTotal
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSelectedProduct: (items) => dispatch(getSelectedProduct(items)),
        addToCorporateCart: (id, relatedProduct, qty) => dispatch(addToCorporateCart(id, relatedProduct, qty)),
        addCards: (form) => dispatch(addCards(form)),

        addEngraving: (form) => dispatch(addEngraving(form)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Productdetail);
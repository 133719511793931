import React, { Component, useCallback } from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import connect from "react-redux/lib/connect/connect";
import Productdetail from './Productdetail';
import $ from 'jquery';
import {Local_API_PATH} from "../../axios/ApiPath";
import {selectCards} from '../../actions/CartAction';


class CardsPopUp extends Component {

    constructor() {
        super();
        this.state = {
            c_cards: [],
            selected_card: {}
        }

    }

    getCard(id){
        const res= axios.get(Local_API_PATH+'getCardById/'+id).then((res)=>{

            this.props.selectCards(res.data['getCard'].card_image,res.data['getCard'].card_name)
            // $('.cardA').click(function() {
            //     $('.cardA').css("background-color", "","color", "");
            //      $(this).css("background-color", "#965537","color", "#fff");

            // });
        })
    }

    componentDidMount() {
        const cardResponse = axios.get(linksType.Local_API_PATH + 'getCards').then((res) => {

            this.setState({
                c_cards: res.data['cards']
            })

        }).catch((err) => {
            console.log(err);
        });
    }


    render() {
        let result = this.state.c_cards.map((items) => {
            return (
                <div class="col-md-3" >
                    <div class="imgColor" >
                        <label class="count"  onClick={()=>{
                            this.getCard(items.id)
                            ReactDOM.findDOMNode(this).parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("selected_card_name")[0].innerText = items.card_name;
                            ReactDOM.findDOMNode(this).parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("selected_card_image")[0].src = linksType.Local_Image_Path + "cards/" + items.card_image;
                            document.getElementsByClassName("bd-example-modal-lg")[0].click()
                            // console.log(ReactDOM.findDOMNode(this).parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("selected_card_image"))
                            // React.findDOMNode(this.refs.cpDev1)
                            // this.setState({
                            //     selected_card: items
                            // });
                        }} >
                            <img src={linksType.Local_Image_Path + "cards/" + items.card_image} class="img-fluid" />
                            <input type="radio" name="radio" />
                            <span class="checkmark"></span>
                            <div class="bg">
                            </div>
                        </label>
                        <h4>{items.card_name}</h4>
                    </div>
                </div>);
        });
        return (
            <React.Fragment>
                {result}
            </React.Fragment>
        )
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        selectCards: (items,cardName) =>  dispatch(selectCards(items,cardName)),


    };
};
export default connect(null, mapDispatchToProps)(CardsPopUp);
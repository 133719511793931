import React, {useEffect, useState} from 'react';
import Header from "../../component/include/Header";
import {useSelector} from "react-redux";
import {Local_Image_Path} from "../../axios/ApiPath";
import Footer from "../../component/include/Footer";
import {adjustCartQty, adjustItemQty, productTotal, removeFromCart, removeFromRowCart} from "../../actions/CartAction";
import connect from "react-redux/lib/connect/connect";
import {Link, NavLink} from 'react-router-dom';
import * as he from "punycode";
import * as linksType from "../../axios/ApiPath";
import Helmet from "react-helmet";

const CartDetail = ({removeFromRowCart, adjustCartQty,cart}) => {

    // const product = useSelector((state) => state.allCartProducts.cart);


    const [total, setTotalPrice] = useState(0)


    useEffect(() => {

        let items = 0;
        let price = 0;
        let product_ids = 0;
        let packPrice=0;
        let boksPrice=0;
        cart.forEach((item) => {


                if(item['type']==='box') {
                    price+=item['total']*item['boxCartQty'];

                }
                else{
                    price +=item['total']*item['readyToshipQty']


                }


        })





        setTotalPrice(price);

    }, [cart,total, setTotalPrice])

    function AddQty(e, row_id, type,ProductQty) {

        let addQty = 1;

        //
         addQty += ProductQty;

        adjustCartQty(row_id, type, addQty)

    }

    function SubtractQty(e, row_id, type,ProductQty) {

        let addQty = 0;

        addQty = ProductQty;
        addQty--;
        if (addQty >= 1) {
            adjustCartQty(row_id, type, addQty)
        }

    }

    let counter=0;
    // const packagesDefine = product.map((item) => {
    //
    // })

    const cartDetails = cart.map((item,i) => {

        if(item['type']==='box') {
            counter++
        }else{

        }

        return (
            <React.Fragment>

                <div className="col-md-6 pr-md-5">
                    <div className="tablone">
                        { item['cartProdcuts'].map((cartProducts) => {
                            if(item['type']!=='box') {
                                return (
                                    <img src={linksType.Local_Image_Path + "products/" + cartProducts.product_image} className="img-fluid" alt="img"/>
                                )
                            }

                        })


                        }

                        {item['type']==='box'?  <img src="images/new4.jpg" className="img-fluid" alt="img"/>:''}


                            <div className="tablContent">

                            <h2>{ item['type']==='box'?'BOKS '+ counter:'Ready To Ship'}
                            <span><i className="fas fa-trash"  onClick={() => removeFromRowCart(item.row_id)}></i></span>
                            </h2>

                            <p>{item['SetPackageName']===40?'Current Package : '+'Core':item['SetPackageName']===55?'Current Package : '+'Premium':''}</p>

                            <ul>
                                <li> <b>Items: </b>

                                {

                                    item['cartProdcuts'].map((cartProducts) => {



                                        return (
                                            <React.Fragment>
                                            {cartProducts.product_name} ,




                                            </React.Fragment>

                                        )


                                    })



                                }
                                </li>
                                <li>
                                {

                                    item['relatedProduct']?item['relatedProduct'].map((relatedProducts) => {
                                        return (
                                            relatedProducts['products'].map((fetch) => {

                                                return(
                                                    <React.Fragment>
                                                       {fetch.product_name} ,  <span> </span>

                                                        {/*<li><b>Description:</b> {stripedHtml??''}</li>*/}


                                                    </React.Fragment>

                                                )
                                            })

                                        )

                                    }):''



                                }
                                </li>
                                <li><b>Boks Top: </b>{item['boksTopData'].name} </li>
                                <li><b>Card: </b>{item['cardFormdata'].cardName} </li>



                            </ul>
                        </div>
                    </div>
                    <div className="row extDetails">
                        <div className="col-md-4">
                            <h5>Price</h5>
                            <b>${item['total']}</b>
                        </div>
                        <div className="col-md-4 text-md-center">
                            <h5>Quantity</h5>
                            <div className="shop-details">
                                <div className="numberone">
                                    <span className="minus" onClick={(e) => SubtractQty(e,item.row_id,item['type'],item['type']==='box'?item['boxCartQty']:item['readyToshipQty'])}>-</span>
                                    <input type="text"  value={item['type']==='box'?item['boxCartQty']:item['readyToshipQty']}/>
                                    <span className="plus"  onClick={(e) => AddQty(e,item.row_id,item['type'],item['type']==='box'?item['boxCartQty']:item['readyToshipQty'])}>+</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-md-right">
                            <h5>Total</h5>



                                <b>${item['type']==='box'?item['total']*item['boxCartQty']:item['total']*item['readyToshipQty']}</b>



                        </div>
                    </div>
                </div>

                {/*<div className="col-md-6 pr-md-5" key={item.row_id}>*/}
                {/*    <div className="tablone" key={item.row_id}>*/}
                {/*        <img src={Local_Image_Path + 'products/' + cartProducts.product_image}*/}
                {/*             className="img-fluid" alt="img"/>*/}
                {/*        <div className="tablContent">*/}
                {/*            <h2>{cartProducts.product_name ?? ''}*/}
                {/*                <span  onClick={() => removeFromRowCart(cartProducts.id,item.row_id)}> <i className="fas fa-trash"></i></span></h2>*/}
                {/*            /!*<p>Original Creme / Mini</p>*!/*/}
                {/*            <br/>*/}
                {/*                <br/>*/}
                {/*            <p className="description">*/}
                {/*                {stripedHtml ?? ''}</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="row extDetails">*/}
                {/*        <div className="col-md-4">*/}
                {/*            <h5>Price</h5>*/}
                {/*            <b>${cartProducts.product_current_price}</b>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-4 text-md-center">*/}
                {/*            <h5>Quantity</h5>*/}
                {/*            <div className="shop-details">*/}
                {/*                <div className="numberone">*/}
                {/*                    <span className="minus" onClick={(e) => SubtractQty(e,cartProducts.id,cartProducts.qty,item.row_id)}>-</span>*/}
                {/*                    <input type="text" value={cartProducts.qty}/>*/}
                {/*                    <span className="plus" onClick={(e) => AddQty(e,cartProducts.id,cartProducts.qty,item.row_id)}>+</span>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="col-md-4 text-md-right">*/}
                {/*            <h5>Total</h5>*/}
                {/*            <b>${cartProducts.qty * cartProducts.product_current_price}</b>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </React.Fragment>
        )

    })


    return (

        <React.Fragment>
            <Header/>
            <Helmet>
                <title>RealBoks | Cart</title>
            </Helmet>
            <section className="cartPage">
                <div className="container">
                    <div className="shipAdress">

                        <h2>Your Cart</h2>
                    </div>
                </div>

                <div className="container">
                <div className="col-md-12">
                            {/*<h6 className="boxHeading">Box 1</h6>*/}
                        </div>
                    <div className="row mainCartRow">
                        
                        {cartDetails}

                    </div>

                </div>


                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="subTotal">
                                <h2>${total??''} <span>SUBTOTAL</span></h2>
                                {/*<h4>or 4 interest-free payments of $63.75 with <a href="#">More info</a></h4>*/}
                                <p>Shipping & taxes calculated at checkout. We accept Visa, MasterCard, American
                                    Express,
                                    and Discover cards. You can also choose to pay with Shopify Pay or After Pay.</p>
                                {/*<a href="#" className="updtCrttwo">Update cart</a>*/}
                                {
                                    cart.length==0?'':
                                    <Link to="/checkout" className="updtCrt btn d-btn btn-brown-pattern">Check
                                        out</Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </section>



            <Footer/>
        </React.Fragment>


    );

}
const mapDispatchToProps = (dispatch) => {
    return {
        adjustCartQty: (row_id, type, qty) => dispatch(adjustCartQty(row_id, type, qty)),

        removeFromRowCart: (row_id) => dispatch(removeFromRowCart( row_id)),
    };
};
const mapStateToProps = (state) => {
    return {
        cart: state.allCartProducts.cart,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CartDetail);
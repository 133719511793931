import * as actionTypes from '../constrant/CartConst';
import uuid from 'react-uuid'
import {act} from "@testing-library/react";
import {toast} from "react-toastify";


const INITIAL_STATE = {
    chooseCart: [],
    chooseBoksTop: [],
    products: [],
    type:[],
    SetPackageName:[],
    boxCartQty:[],

    cart: [],
    formsdata: [],
    selection: [],
    productTotal: [],
    currentItem: null,

    //corporative
    CorporateSelectionProducts: [],
    ProductDetail: [],
    CorporateSelection: [],

    //reset pass for redux
    randToken:[]

};


const AddToCartReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case actionTypes.LOAD_PRODUCT:


            return {
                ...state,
                products: action.payload,

            };

        case actionTypes.ADD_TO_CART:

            // Great Item data from products array


            const item = state.products.find(
                (product) => product.id === action.payload.id
            );

            // Check if Item is in cart already
            const inCart = state.selection.find((item) =>
                item.id === action.payload.id ? true : false
            );

            var di = {
                ...state,
                selection: inCart
                    ? state.selection.map((item) =>
                        item.id === action.payload.id
                            ? {...item, qty: item.qty + 1}
                            : item
                    )
                    : [...state.selection, {...item, qty: 1}],

            };
           /// localStorage.setItem('cartLocal', JSON.stringify(di))
            return di
        //
        // case actionTypes.REMOVE_FROM_CART:
        //     var removeItems= {
        //         ...state,
        //         selection: state.selection.filter((item) => item.id !== action.payload.id),
        //     };
        //     localStorage.setItem('cartLocal',JSON.stringify(removeItems))
        //     return removeItems;
        case actionTypes.REMOVE_FROM_SELECTION:
            var removeItems = {
                ...state,
                selection: state.selection.filter((item) => item.id !== action.payload.id),
            };
            //localStorage.setItem('cartLocal', JSON.stringify(removeItems))
            return removeItems;

        case actionTypes.ADJUST_ITEM_QTY:
            return {
                ...state,
                selection: state.selection.map((item) =>
                    item.id === action.payload.id
                        ? {...item, qty: +action.payload.qty}
                        : item
                ),
            };
        case actionTypes.LOAD_CURRENT_ITEM:
            return {
                ...state,
                currentItem: action.payload,
            };

        case actionTypes.PRODUCT_TOTAL:
            return {
                ...state,
                productTotal: action.payload,
            };

        case actionTypes.SELECT_CARDS:

            let card = {
                ...state,
                    chooseCart: {...state.chooseCart,
                    image: action.payload.image,
                    cardName:action.payload.cardName
                }
            };
            if (card) {
                // toast.success('Card Selected')
                return card
            } else {
                toast.error('Not Selected Card')
            }
        case actionTypes.ADD_ENGRAVING_FORM:

            let engrForm = {
                ...state,
                chooseBoksTop: {...state.chooseBoksTop, ...action.payload}

            };
            return engrForm;
        case actionTypes.SELECT_BOKS_TOP:
            let BoksTop = {
                ...state,
                chooseBoksTop: {
                    ...state.chooseBoksTop,
                    image: action.payload.image,
                    price: action.payload.price,
                    id: action.payload.id,
                    name: action.payload.name
                }
            };
            // state.cart.total

            if (BoksTop) {
                // toast.success('Card Selected')
                return BoksTop
            } else {
                toast.error('Not Selected Card')
            }


        case actionTypes.ADD_CARD_FORM:

            let data = {
                ...state,
                chooseCart: {...state.chooseCart, ...action.payload}

            };
            return data;

        case actionTypes.Set_Package_Name:
            return{
                ...state,
                SetPackageName:action.payload

            }
        case actionTypes.REFRESH_SELECTION:


            let st = {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        row_id: uuid(),
                        cartProdcuts: state.selection,
                        cardFormdata: state.chooseCart,
                        boksTopData: state.chooseBoksTop,
                        total: state.productTotal,
                        SetPackageName:state.SetPackageName,
                        type:'box',
                        boxCartQty:1


                    }
                ]
            };

            st.selection = [];
            st.chooseCart = [];
            st.chooseBoksTop = [];
            return st;


        case actionTypes.ADJUST_ITEM_CART_QTY:
            let boxqt=0;
            state.cart.forEach((d, i) => {

                if (d.row_id == action.payload.id) {
                    if(d.type==='box'){
                        let total=0;
                        boxqt = action.payload.qty
                        d['boxCartQty']=boxqt
                        // d['total'] =boxqt*d['total'];

                    }else{
                        boxqt = action.payload.qty
                        d['readyToshipQty']=boxqt
                        // d['total'] =boxqt*d['total'];
                    }

                        // d['cartProdcuts'].forEach((pd, pi) => {
                        //     if (pd.id == action.payload.id) {
                        //         state.cart[i]['cartProdcuts'][pi].qty = action.payload.qty;
                        //
                        //
                        //     }
                        // });


                }
            })
            //
            // state.cart.forEach((d, i) => {
            //     if (d.row_id == action.payload.row_id) {
            //         state.cart[i]['total'] = 0;
            //
            //             state.cart[i]['total'] += ;
            //         });
            //     }
            // })


            return {
                ...state,
                cart: [...state.cart],

            }
        case actionTypes.REMOVE_FROM_CART:
            let index=0
          const a=  state.cart.forEach((d, i) => {

                if (d.row_id == action.payload.row_id) {
                    index=i;
                    // state.cart[i]['total'] = 0;
                    // state.cart[i]['row_id']=[];
                    // d['boksTopData']=[];
                    // d['cardFormdata']=[];
                    //
                    // // state.cart[i]['bo']
                    // d['cartProdcuts']=[];
                    // d['cartProdcuts'].forEach((pd, pi) => {
                    //
                    //     state.cart[i]['cartProdcuts'] = state.cart[i]['cartProdcuts'].filter((item) => item.id !== action.payload.id)
                    //     state.cart[i]['total'] += pd.product_current_price * pd.qty;
                    //
                    // })

                }
            })
            state.cart.splice( index,1);
            return {
                ...state,
                cart: [...state.cart],

            }
        case actionTypes.CLEAR_CART:
            let clear = {
                ...state,
            }
            clear.cart = [];
            return clear;


        case actionTypes.LOAD_CORPORATE_PRODUCTS:


            return {
                ...state,
                CorporateSelectionProducts: action.payload,

            };
        case actionTypes.GET_SELECTED_PRODUCT:
            state.chooseBoksTop=[];
            state.chooseCart=[];
            return {
                ...state,
                ProductDetail: action.payload,

            };
        case actionTypes.ADD_TO_CORPORATE_CART:

            // Great Item data from products array
            const corpProduct = state.CorporateSelectionProducts.find(
                (product) => product.id === action.payload.id
            );

            // Check if Item is in cart already
            const corpCart = state.CorporateSelection.find((item) =>

                item.id === action.payload.id ? true : false
            );

            const addToCart = corpCart ? state.CorporateSelection.map((item) =>
                    item.id === action.payload.id
                        ? {...item, qty: item.qty + 1}
                        : item
                )
                : [...state.CorporateSelection, {...corpProduct, qty: action.payload.qty},];

            let readyToShiptotal=0;
            let boksPrice=+state.chooseBoksTop.price;
            readyToShiptotal=parseFloat(boksPrice)+parseFloat(addToCart[0].product_current_price);



            var corporateCart = {
                ...state,

                cart: [
                    ...state.cart,
                    {
                        row_id: uuid(),
                        cartProdcuts: addToCart,
                        relatedProduct:action.payload.relatedProduct,
                        cardFormdata: state.chooseCart,
                        boksTopData: state.chooseBoksTop,
                        total:readyToShiptotal,
                        readyToshipQty:1,

                    }


                ]


            };
            // localStorage.setItem('cartLocal', JSON.stringify(di))

            corporateCart.chooseCart = [];
            corporateCart.chooseBoksTop = [];

            return corporateCart
        case actionTypes.Forget:
            return{
                ...state,
                randToken: action.payload


            }
        default:
            return state;
    }
};
export default AddToCartReducer;

import React, {useEffect, useState} from 'react';
import connect from "react-redux/lib/connect/connect";
import axios from "axios";
import * as linksType from '../../axios/ApiPath'
import {addToCart, adjustItemQty, loadCurrentItem, removeFromCart, selectCards} from "../../actions/CartAction";
import {Local_API_PATH} from "../../axios/ApiPath";
import $ from 'jquery';
const Cards = ({cardsData,addToCart,selectCards}) => {

    const [cards, setCards] = useState([]);

    function getCard(id){
        const res= axios.get(Local_API_PATH+'getCardById/'+id).then((res)=>{

            // console.log(res.data['getCard'].card_name);
            selectCards(res.data['getCard'].card_image,res.data['getCard'].card_name)
            // $('.cardA').click(function() {
            //     $('.cardA').css("background-color", "","color", "");
            //      $(this).css("background-color", "#965537","color", "#fff");

            // });
        })
    }
    const cardsList=cardsData.map((items)=> {
        return (
            <div className="col-lg-3 col-md-4 col-sm-6" key={items.id}>
                <div className="crdBox">
                    <a id="cardA" className="cardA" href="javascript:void(0)"  onClick={(e) => {
                            getCard(items.id);
                            $('.cardA').css("background-color", "","color", "");
                            $('.cardA strong').css("background-color", "","color", "");
                            let tagname = e.target.tagName;
                            if(tagname == "IMG" || tagname == "FIGURE" || tagname == "STRONG"){
                                if(tagname == "IMG"){
                                    e.target.parentElement.parentElement.style.background = "#965537";
                                } else {
                                    e.target.parentElement.style.background = "#965537";
                                }
                            } else {
                                e.target.style.background = "#965537";
                            }
                        }} >
                        <figure><img src={linksType.Local_Image_Path+'cards/'+items.card_image} className="img-fluid"
                                     alt="img"/>
                        </figure>
                        {items.card_name??''}
                    </a>
                </div>
            </div>
        )
    })
return(

    <div className="">
        <div className="card">
            <h2><strong>Select a card for your BOKS</strong></h2>
            <p>Choose the perfect card for the occasion from our selection of
                exclusive
                designs. Our team handwrites each and every note to keep your gifts
                personal. (Plus, we have great handwriting.)</p>
        </div>

        <div className="row">

            {cardsList??''}
        </div>
    </div>
)

}
const mapDispatchToProps = (dispatch) => {
    return {
        selectCards: (items,cardName) =>  dispatch(selectCards(items,cardName)),


    };
};
export default connect(null, mapDispatchToProps)(Cards)
import React, {Component, useCallback, useState} from 'react';
import Footer from "../../component/include/Footer";
import Header from "../../component/include/Header";
import Products from "./Products";

import {GetProducts, setProducts} from "../../actions/ProductAction";
import {getCartProducts, loadProduct, refreshCart, selectCards} from '../../actions/CartAction';
import axios from "axios";
import connect from "react-redux/lib/connect/connect";
import AddToCartList from "./AddToCartList";
import FooterLineProduct from "./FooterLineProduct";
import Cards from "./Cards";
import * as linksType from "../../axios/ApiPath";
import CardToFrom from "./CardToFrom";
import {Link} from 'react-router-dom';
import Search from "./Search";
import BoksTops from './BoksTops';

import { Helmet } from 'react-helmet';

class BuildBox extends Component {

    constructor() {
        super();
        this.state = {
            packages: [],
            cartProdcut: [],
            cards: [],
            boks_tops:[],
            counter:false
        }

    }


    componentDidMount() {
        // if(this.props.selection.length !== 0){
        //
        //     this.setState({
        //         counter:true
        //     })
        // }
        // else{
        //     this.setState({
        //         counter:false
        //     })
        // }
        // if(this.props.chooseBoksTop.length !== 0){
        //
        //     this.setState({
        //         counter:true
        //     })
        // }
        window.scrollTo(0, 0)
        var script = document.createElement('script')
        script.src = '/js/custom.min.js';
        script.class = "external-script"
        document.body.appendChild(script);


        let token=localStorage.getItem('token');


        const response = axios.get(linksType.Local_API_PATH+'getPrdouct',{
            headers: {"Authorization" : `Bearer ${token}`}
        })
            .then((res) => {

            this.props.setProducts(res.data['getProducts'])

            this.props.loadProduct(res.data['getProducts'])
            this.setState({
                packages: res.data['package']
            })
        }).catch((err) => {

                if(err.response.status==401){
                    const {history} = this.props;
                    history.push("/logout")
                }else{
                    const {history} = this.props;
                    history.push("/logout")
                }
        })
        //
        const cardResponse = axios.get(linksType.Local_API_PATH + 'getCards').then((res) => {

            this.setState({
                cards: res.data['cards']
            })

        }).catch((err) => {
            if(err.response.status==401){
                const {history} = this.props;
                history.push("/logout")
            }else{
                const {history} = this.props;
                history.push("/logout")
            }
        });

        const boksTopResponse = axios.get(linksType.Local_API_PATH + 'getBoksTop').then((res) => {

            this.setState({
                boks_tops: res.data['boks_top']
            })

        }).catch((err) => {
            if(err.response.status==401){
                const {history} = this.props;
                history.push("/logout")
            }else{
                const {history} = this.props;
                history.push("/logout")
            }
        });

        //jquery
        var add=0;
        this.setState({
            counter:add+1
        })

    }
    BuildNewBox= event =>{
        event.preventDefault();
      this.props.refreshCart();
    }
    ViewCart= event =>{
        event.preventDefault();
        this.props.refreshCart()
        //  const { history } = this.props;
        // history.push("/cart");

    }
    handleSubmit = event => {

        event.preventDefault();

    }



    render() {


        // if(chooseBoksTop.length > 0){
        //     setBool('true')
        // }
        // else{
        //     setBool('false')
        // }

        localStorage.setItem('pack', JSON.stringify(this.state.packages))
        return (

            <React.Fragment>
                <link href="%PUBLIC_URL%/css/buildbox.min.css" rel="stylesheet" />
                <Header/>
                <Helmet>
                    <title>RealBOKS | Build Your BOKS</title>
                </Helmet>


                <section className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Build Your BOKS</h2>
                                <ul>
                                    <li><Link to="/">HOME</Link></li>
                                    <li><span>Build Your BOKS</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="stepPage">
                    <div className="container" id="grad1">
                        <div className="row justify-content-center mt-0">
                            <div className="col-md-10">
                                <ul id="progressbar">
                                    <li className="active" id="account"><strong>Step</strong>Items</li>
                                    <li id="boks_top"><strong>Step</strong>Engraving</li>
                                    <li id="personal"><strong>Step</strong>Card</li>
                                    <li id="payment"><strong>Step</strong>Client Details</li>
                                    <li id="confirm"><strong>Step</strong>Checkout</li>
                                </ul>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">

                                <form id="msform" >
                                    <fieldset>
                                        <div className="card">
                                            <h2><strong>CHOOSE YOUR ITEMS</strong></h2>
                                            <p>We’ve hand-selected the best products in one place. Select from the items
                                                below
                                                and fill up your boks! <span>Pick your products first</span> and our
                                                program
                                                will
                                                automatically select the boks size!</p>
                                        </div>


                                        <AddToCartList packageData={this.state.packages}/>


                                        {/*component here*/}
                                        {/*<div className="col-md-5">*/}
                                        {/*    <div className="boxContent">*/}
                                        {/*        <h2>Box Contents</h2>*/}
                                        {/*        <ul>*/}
                                        {/*            <li>1</li>*/}
                                        {/*            <li>Gift Packaging, Card & Hand Wrapping</li>*/}
                                        {/*            <li>$7.00</li>*/}
                                        {/*        </ul>*/}
                                        {/*        <ul>*/}
                                        {/*            <li>1</li>*/}
                                        {/*            <li>Dream Glow Sheet Mask</li>*/}
                                        {/*            <li>$8.00</li>*/}
                                        {/*        </ul>*/}
                                        {/*        <ul>*/}
                                        {/*            <li>1</li>*/}
                                        {/*            <li>Sweet Dreams Chocolate Beauty Bar</li>*/}
                                        {/*            <li>$12.00</li>*/}
                                        {/*        </ul>*/}
                                        {/*        <span>Current Packaging | Mini</span>*/}
                                        {/*        <h6>Box Subtotal: $7.00</h6>*/}
                                        {/*        <a href="#" className="btn d-btn btn-brown-pattern ">Complete*/}
                                        {/*            Box</a>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                       {/*<Search />*/}


                                        <div className="row">

                                            <div className="col-md-12">

                                                    <div className="reasonSelect">
                                                        <ul>

                                                            <li>
                                                                <input type="text" onSubmit={this.handleSubmit}  placeholder="Search....."/>

                                                            </li>
                                                        </ul>
                                                    </div>

                                            </div>

                                        </div>

                                        <Products productData={this.props.products}/>



                                            <input type="button" name="next"  disabled={this.props.selection.length===0?'disabled':!this.props.selection}
                                            className="next action-button btn d-btn btn-brown-pattern"
                                            value="Next Step"/>


                                    </fieldset>
                                    <fieldset>
                                        {/*cardComponenthere*/}

                                        <BoksTops BoksTopData={this.state.boks_tops}/>


                                        <input type="button" name="previous"

                                               className="previous action-button btn d-btn btn-brown-pattern mr-2"
                                               value="Previous"/>
                                        <input type="button" name="next" disabled={this.props.chooseBoksTop.length===0?'disabled':!this.props.chooseBoksTop}
                                               className="next action-button btn d-btn btn-brown-pattern"
                                               value="Next Step"/>
                                    </fieldset>
                                    <fieldset className="step-class">
                                        {/*cardComponenthere*/}

                                        <Cards cardsData={this.state.cards}/>


                                        <input type="button" name="previous"

                                               className="previous action-button btn d-btn btn-brown-pattern mr-2"
                                               value="Previous"/>
                                        <input type="button" name="next" disabled={this.props.chooseCart.length===0?'disabled':!this.props.chooseCart}
                                               className="next action-button btn d-btn btn-brown-pattern"
                                               value="Next Step"/>
                                    </fieldset>

                                    <fieldset>


                                        {/*inputFormcard*/}
                                        <CardToFrom/>
                                        <input type="button" name="previous"
                                               className="previous action-button btn d-btn btn-brown-pattern mr-2"
                                               value="Previous"/>
                                        <input type="button" name="make_payment"  disabled={this.props.selection.length===0?'disabled':!this.props.selection}
                                               className="next action-button btn d-btn btn-brown-pattern"
                                               value="Confirm" onClick={(e)=>this.ViewCart(e)}/>
                                    </fieldset>

                                    <fieldset>
                                        <div className="bldngBox">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <div className="cstmGift">
                                                        <div className="card">
                                                            <h2><strong>BUILDING YOUR BOKS</strong></h2>
                                                            <p>Please wait a moment while we prepare your custom gift
                                                                boks!</p>
                                                        </div>
                                                        <img src="images/check.jpg" className="img-fludi" alt="img"/>
                                                        <Link to='/cart'
                                                           className="cartBtn btn d-btn btn-brown-pattern">View
                                                            Cart</Link>
                                                            <Link to='/BOKS' onClick={()=>{window.location = "BOKS"}}
                                                           className="anthrBtn">Build another boks</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>



                <FooterLineProduct counters={this.state.counter}/>

                <Footer/>
            </React.Fragment>
        );
    }


}

const mapStateToProps = state => {

    return {
        productList: state.allProducts,
        products: state,
        chooseCart:state.allCartProducts.chooseCart,
        selection:state.allCartProducts.selection,
        chooseBoksTop:state.allCartProducts.chooseBoksTop

        // chooseBoksTop:state.allCartProducts.chooseBoksTop,


    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setProducts: (products) => dispatch(setProducts(products)),
        loadProduct: (items) => dispatch(loadProduct(items)),
        refreshCart: (items) => dispatch(refreshCart(items)),


    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuildBox);
import React from 'react';
import axios from 'axios';
import {ActionTypes} from '../constrant/ProductsConst';

export const setProducts=(products)=>{

    return {
        type:ActionTypes.SET_PRODUCTS,
        payload:products
    };

};

export const selectedProduct=(products)=>{

    return {
        type:ActionTypes.SELECTED_PRODUCTS,
        payload:products
    };

};


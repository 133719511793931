export const LOAD_PRODUCT = "LOAD_PRODUCT";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADJUST_ITEM_QTY = "ADJUST_ITEM_QTY";
export const REMOVE_FROM_SELECTION = "REMOVE_FROM_SELECTION";
export const LOAD_CURRENT_ITEM = "LOAD_CURRENT_ITEM";
export const PRODUCT_TOTAL="PRODUCT_TOTAL";
export const SELECT_CARDS="SELECT_CARDS";
export const ADD_CARD_FORM="ADD_CARD_FORM";
export const ADD_ENGRAVING_FORM="ADD_ENGRAVING_FORM";

export const SELECT_BOKS_TOP="SELECT_BOKS_TOP";
export const ADD_BOKS_TOP_FORM="ADD_BOKS_TOP_FORM";

export const REFRESH_SELECTION="REFRESH_SELECTION";

export const ADJUST_ITEM_CART_QTY="ADJUST_ITEM_CART_QTY";
export const REMOVE_FROM_CART="REMOVE_FROM_CART";
export const CLEAR_CART="CLEAR_CART";


export const LOAD_CORPORATE_PRODUCTS = "LOAD_CORPORATE_PRODUCTS";
export const GET_SELECTED_PRODUCT = "GET_SELECTED_PRODUCT";
export const ADD_TO_CORPORATE_CART = "ADD_TO_CORPORATE_CART";

export const Set_Package_Name="Set_Package_Name";

//reset password redux
export const Forget="Forget";
import React, {useState} from 'react';
import {Link,NavLink} from 'react-router-dom';
import connect from "react-redux/lib/connect/connect";
import ContactUs from "../../pages/contact_us/ContactUs";
import { useLocation } from 'react-router-dom';

const Header = ({selection,cart}) => {
    const[cartCounter,setCounter]=useState(0);

    // useEffect(() => {
    //  let count=0
    //     selection.forEach((
    //
    //     ))
    // })
    let location = useLocation();
    var path=  location.pathname;
    var token=localStorage.getItem('token')

    return (

        <header className="wow fadeInDown" data-wow-delay="0.5s">
            <div className="top-bar">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="socialTop">
                                <ul>
                                    <li>
                                        <a href="https://www.instagram.com/realboksgifts/"   target="-">
                                            <i className="fab fa-instagram"></i>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#">
                                            <i className="fab fa-facebook-f"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="logo">
                                <Link to="/">
                                    {
                                        location.pathname=='/'?
                                        <img src="../images/RealBoks.gif" class="img-fluid"/>
                                            :
                                        <img src="../images/logo.png" class="img-fluid my-4"/>
                                    }
                                    </Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="srchList">
                                <ul>
                                    {/* <li>
                                        <a href="#search">Search</a>
                                    </li> */}

                                    <li>
                                        {


                                            <Link to={token?'/logout':'/login'}>{token?'logout':'login'}</Link>
                                        }
                                    </li>
                                    <li>
                                        {token?'':
                                        <Link to={'/register'}>register</Link>}
                                    </li>
                                    <li>
                                        <Link to="/cart">
                                            <i className="far fa-shopping-bag"></i>
                                            <span>{cart.length??''}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-navigate">
                <div className="an-navbar">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg p-0">
                            <Link className="navbar-brand d-none" to="/">
                                <img alt="img" src="../images/logo.png"/>

                            </Link>
                            <button aria-controls="navbarSupportedContent" aria-expanded="false"
                                    aria-label="Toggle navigation" class="navbar-toggler"
                                    data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
                                <span class="fa fa-bars"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav m-auto">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">Home
                                            <span className="sr-only">(current)</span>
                                        </Link>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/products">Ready to Ship</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/BOKS">Build Your BOKS</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/luxBOKS">The LuxBOKS</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link className="nav-link" to="/ContactUs">Contact Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/about">About Us</Link>
                                    </li>
                                    {/*<li class="nav-item">*/}
                                    {/*    <a className="nav-link" href="#">Blog</a>*/}
                                    {/*</li>*/}
                                    <li class="nav-item">
                                        <Link className="nav-link" to='/faqs'>FAQ</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>


    );
}
const mapStateToProps = (state) => {
    return {
        cart: state.allCartProducts.cart,
    };
};


export default connect(mapStateToProps)(Header)
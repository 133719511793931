import React from 'react';
import Header from "../../component/include/Header";
import Footer from "../../component/include/Footer";
import {Link, NavLink} from 'react-router-dom';
import Helmet from "react-helmet";
const About = () => {

    return (
        <React.Fragment>
            <Header />
            <Helmet>
                <title>RealBOKS | About</title>
                <title>RealBOKS | About</title>
            </Helmet>
            <section className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>About Us</h2>
                            <ul>
                                <li><Link to="/">HOME</Link></li>
                                <li><span>ABOUT US</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section className="aboutPage">
                <div className="container-fluid px-5">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="aboutContent">
                                <h2>About <span>Real</span>BOKS</h2>
                                <p>In 2019, Joe and I decided to leave the high energy of the Seattle Metro area. We grabbed our 5 horses and 2 dogs and hoofed it across country to the bustling metropolis of Berry, Kentucky; population 250. Part of that process was selling 3 homes and buying 2 in a very short timeframe. This was a wonderful and educational experience. Dealing with 6 different agents gave us a peak behind the curtain into the Real Estate world.</p>
                                <p>Most of what we learned is how hard Real Estate Professionals work. There never seems to be an end to the day let alone an entire day off. A Relaxing vacation can be an oxymoron.</p>
                                <p>We learned that taking care of your clients is the most important part of your job. For some, the last step is the closing gift. Often overlooked or undervalued for its impact, this step is the final opportunity for a lasting impression. Giving nothing potentially leaves a client feeling out of sorts.. Giving a gift that is inappropriate can feel awkward. Giving the gift that strikes the right tone of care, value and personalization is no simple task.</p>
                                <p>To this end, RealBOKS was created. Our mission is to streamline the gifting process and facilitate a personalized, memorable gifting experience for both you and your client.</p>
                                <p>Our team of dedicated craftsmen construct our exclusive wood boxes on site. Each BOKS is carefully created with heart and built to be a treasured keepsake. Our engraving options personalize the BOKS striking just the right cord of thoughtful appreciation.</p>
                                <p>We source inspired, fresh and innovative products which are beautifully packaged in our signature style. We combine items in a distinctive way ensuring a delightful unboxing experience for your clients.</p>
                                <p>A Real Estate Professionals time is valuable. Our job is to make this last connection effortless.</p>
                                <blockquote>"Success in real estate comes down to two factors: taking care of and valuing the customer."<br/> —
							Michael Miedler, Century 21 CEO</blockquote>
                                <Link to="/products" className="themeBtn d-btn btn-brown-pattern">Shop Now</Link>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src="images/update3.jpg" className="img-fluid w-100" alt="img" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="featurProduc ourGift">
                <div class="container-fluid px-5">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="overlay">
                                <div class="row flex-row-reverse">
                                    <div className="col-md-7">
                                        <div className="realhead">

                                            <h2>The <span>Real</span>BOKS</h2>
                                            <p>Our greatest desire is to create a memorable gifting experience for Real Estate Professionals and their Clients. We want that process to be simple, time-saving and thoughtful.</p>
                                            <h3>Ready-To-Ship</h3>
                                            <p>
											These options are designed to help save you valuable time. Thoughtfully curated and beautifully packaged BOKSes will surely fit your needs. All engraving options are available.
											</p>
                                            <p>
											If you prefer to curate your own BOKS, for example adding special touches for kids or pet lovers, use our simple Build Your BOKS feature. Select your items and level of engraving, and you’re done!
											</p>
                                            <h3>The LuxBOKS</h3>
                                            <p>
											Our LuxBOKS concierge service covers anything imaginable. From something as simple as a custom size engraved BOKS to the arranging of a weekend getaway to the purchase of appliances or a roof repair. We will facilitate the procurement, deal with delivery logistics and construct an appropriate, engraved BOKS to create that memorable gifting experience that everyone loves. Thus, freeing up the agent's valuable time and eliminating unnecessary stress.
											</p>
                                            <p class="mb-3">Let your creativity run free. We will make it happen!</p>
                                            <Link to="/BOKS" className="themeBtn btn-1c chngbtn">Shop Now
                                                </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <img src="images/prodc14.jpg" className="img-fluid" alt="img" />
                                        {/* <div className="cupOverlay"> */}

                                        {/* <a data-fancybox=""
                                                href="https://www.youtube.com/watch?v=cKjdTA91xPQ&amp;feature=youtu.be">
                                                <i className="fas fa-play"></i>
                                            </a> */}
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/*<section>*/}
            {/*    <div className="map">*/}
            {/*        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13004082.928417291!2d-104.65713107818928!3d37.275578278180674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1615455673542!5m2!1sen!2s" width="100%" height="550" style={{ border:0 }} allowfullscreen="" loading="lazy"></iframe>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <Footer/>
        </React.Fragment>

    );
}
export default About;
import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Header from "../../component/include/Header";
import connect from "react-redux/lib/connect/connect";
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import {adjustCartQty, clearCart, removeFromRowCart} from "../../actions/CartAction";
import Footer from "../../component/include/Footer";
import $ from 'jquery';

class PaymentMethod extends Component {

    constructor(props) {

        super(props);
        const profileData = localStorage.getItem('user')
        var data = JSON.parse(profileData)
        this.state = {
            user_id: data.id, month: null, year: null, card_number: null, cvc: null, validDetails: null
            //     AllData: [],
            //     totalCartPrice:0
        }

    }
    componentDidMount() {



    }
    handleSubmit = e => {
        e.preventDefault();


        var fewSeconds = 10;
        // Ajax request
        var btn = $("#btn");
        btn.prop('disabled', true);
        setTimeout(function () {
            btn.prop('disabled', false);
        }, fewSeconds * 1000);


        // Ajax request
        $('#preloader').show();
        $('#status').show();
        $(window).on('load', function() { // makes sure the whole site is loaded
            $('#status').fadeOut(); // will first fade out the loading animation
            $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
            $('body').delay(350).css({'overflow':'visible'});
        })



        const paymentInputform = {
            user_id: this.state.user_id,
            month: this.month,
            card_number: this.card_number,
            year: this.year,
            cvc: this.cvc,
        }

        const CartData = JSON.stringify(this.props.cart)
        const formData =JSON.stringify(this.props.location.state)// JSON.stringify(this.props.location.state);
        const paymentForm = JSON.stringify(paymentInputform);
        let token=localStorage.getItem('token');

        //

        const cardResponse = axios.post(linksType.Local_API_PATH + 'checkout',    {

                data: CartData,
                paymentForm: paymentForm,
                formData:formData,

            // formData:formDatas//this.props.location.state
            }
        ).then((res) => {


            if (res.data === 'succeeded') {
                this.props.clearCart();

                const {history} = this.props;
                history.push("/ThankYou");
                $('#preloader').css('display','none');
                $('#status').css('display','none');
            } else {
                let msg = 'Please Fill valid Card Detail';
                this.setState({
                    validDetails: msg,
                })
                var btn = $("#btn");
                btn.prop('disabled', false);
                $('#preloader').css('display','none');
                $('#status').css('display','none');
            }


        }).catch((err) => {
            if(err.response.status==500){


                let msg=err.response.statusText;
                this.setState({
                    validDetails: msg,
                })
                btn.prop('disabled', false);
                $('#preloader').css('display','none');
                $('#status').css('display','none');
            }

        });

    }

    render() {
        console.log(this.props.cart)

        return (
            <React.Fragment>
                <Header/>
                <div id="preloader" style={{display:'none'}}>
                    <div id="status" style={{display:'none'}}>&nbsp;</div>
                </div>
                <section className="banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Payment</h2>
                                <ul>
                                    <li><Link to="/">HOME</Link></li>
                                    <li><span>Payment</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="check-out-section">
                    <div className="container">
                        <div className="check-out-form check-out-3">
                            <h2 className="primary-heading">Payment Methods </h2>

                            <div className="col-12 offset-lg-1 col-lg-10">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row tabs-row fields-row">
                                        <div className="col-12 text-center mb-4">
                                            <img src="images/card-img.png" alt=""/>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>CARD NUMBER</label>
                                                <input type="text" className="form-control" maxLength="16" placeholder="4242424242424242 (16 digits)"
                                                       onChange={e => this.card_number = e.target.value}
                                                    // pattern='.{16}'

                                                       required/>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-6">*/}
                                        {/*    <div className="form-group">*/}
                                        {/*        <label>name on card</label>*/}
                                        {/*        <input type="text" className="form-control" placeholder="NAME ON CARD"*/}


                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>expiration Year</label>
                                                <select className="form-control" name="year"
                                                        onChange={e => this.year = e.target.value} required>
                                                    <option value="">Please Select</option>

                                                    <option value='22'>2022</option>
                                                    <option value='23'>2023</option>
                                                    <option value='24'>2024</option>
                                                    <option value='25'>2025</option>
                                                    <option value='26'>2026</option>
                                                    <option value='27'>2027</option>
                                                    <option value='28'>2028</option>
                                                    <option value='29'>2029</option>
                                                    <option value='30'>2030</option>


                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>expiration Month</label>
                                                <select className="form-control" name="month"
                                                        onChange={e => this.month = e.target.value} required>
                                                    <option value="">Please Select</option>
                                                    <option value='1'>01</option>
                                                    <option value='2'>02</option>
                                                    <option value='3'>03</option>
                                                    <option value='4'>04</option>
                                                    <option value='5'>05</option>
                                                    <option value='6'>06</option>
                                                    <option value='7'>07</option>
                                                    <option value='8'>08</option>
                                                    <option value='9'>09</option>
                                                    <option value='10'>10</option>
                                                    <option value='11'>11</option>
                                                    <option value='12'>12</option>


                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>cvv</label>
                                                <input type="text" className="form-control" maxLength="3" placeholder="123 (CVV)"
                                                       onChange={e => this.cvc = e.target.value}
                                                       required pattern='.{3}'

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-25">
                                            {/*<div className="col-md-12 checkbox">*/}
                                            {/*    <input type="checkbox" id="box-2"/>*/}
                                            {/*    <label htmlFor="box-2"><h5>save card</h5>*/}
                                            {/*        information is encrypted and securely stored.</label>*/}
                                            {/*</div>*/}
                                        </div>

                                        <div className="col-12 mt-20">
                                            <p className="text-danger text-center">{this.state.validDetails ?? ''}</p>
                                            <button className="btn d-btn w-100 btn-brown-pattern" id="btn">Place Order
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {

        cart: state.allCartProducts.cart
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        clearCart: () => dispatch(clearCart()),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);
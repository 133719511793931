import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import connect from "react-redux/lib/connect/connect";
import axios from "axios";

import { addToCart, loadCurrentItem, loadProduct } from "../../actions/CartAction";
import * as linksType from "../../axios/ApiPath";
import ProductDetailPopUp from '../BuildBox/ProductDetailPopUp';
import $ from 'jquery';


const Products = ({ cart, productData, addToCart, loadCurrentItem }) => {

    const product = useSelector((state) => state.allProducts.productList);

    const producList = product.map((product) => {
        return (
            <ProductDetailPopUp propsshowModal={true} product={product}/>

        );  
    })
    return (
        <div className="row">
            {producList}

        </div>

    )


}

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (id) => dispatch(addToCart(id)),

        loadCurrentItem: (item) => dispatch(loadCurrentItem(item)),
    };
};


export default connect(null, mapDispatchToProps)(Products)



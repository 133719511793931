import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import axios from "axios";
import * as linksType from "../../axios/ApiPath";

const Logout = () => {

    let token = localStorage.getItem('token');
    // useEffect(() => {
    //
    //     const response = axios.post(linksType.Local_API_PATH + 'logout',{
    //         headers: {"Authorization" : `Bearer ${token}`}
    //     }).then((res) => {
    //         console.log(res.data);
    //
    // }).catch((err) => {
    //
    //     console.log(err.response.status)
    //
    // })

    // localStoragele.log(localStorage.getItem('state').length);
    // localStorage.removeItem("token");
    // localStorage.removeItem("user");
    // // localStorage.removeItem("state");
    // // localStorage.removeItem("pack");
    // // localStorage.removeItem("cartLocal");

    // window.location.reload();

    // })

    localStorage.clear();

    let history = useHistory();

    history.push('/login')
    history.go(0)
    return (<div></div>)

}
export default Logout
import React from 'react';

class Search extends React.Component {
    handleSubmit = event => {

        event.preventDefault();
        console.log(1)
    }

    render() {
        return (
            <div className="row">

                <div className="col-md-12">
                    <form onSubmit={this.handleSubmit} >
                        <div className="reasonSelect">
                            <ul>

                                <li>
                                    <input type="text"  placeholder="Search....."/>

                                </li>
                            </ul>
                        </div>
                    </form>
                </div>

            </div>

        )
    }
}

export default Search;
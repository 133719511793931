import React from 'react';
import axios from "axios";
import * as linksType from "../../axios/ApiPath";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery'
class RegisterForm extends React.Component {

    constructor() {
        super();
        this.state = {
            first_name: null,
            last_name: null,
            email: null,
            password: null,
            cnf_password: null,
            license:null,
            mls:null,
            errors:null
        }
        $(document).ready(function(){
            $('.creatAccount').parents('body').css('zoom','1')
        })
    }
    handleSubmit= event =>{
            event.preventDefault();
        const formData = {
            first_name: this.first_name,
            last_name: this.last_name,
            license: this.license,
            mls: this.mls,
            email: this.email,
            password: this.password,
            cnf_password: this.cnf_password,

        }

        const checkoutForm = JSON.stringify(formData);

        const cardResponse = axios.post(linksType.Local_API_PATH + 'register', formData
        ).then((res) => {

                if(res.status===201){

                    this.setState({
                        first_name:res.data.first_name??null,
                        last_name: res.data.last_name??null,
                        email: res.data.email??null,
                        password: res.data.password??null,
                        cnf_password:  res.data.cnf_password??null,
                    })
                }else {
                    const profile=JSON.stringify(res.data.profile)
                    localStorage.setItem('token', res.data.success['token']);
                    localStorage.setItem('user',profile)

                    const {history} = this.props;
                   history.push("/")
                }
        }).catch((err) => {
            console.log(err.data);
        });


    }
    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>RealBoks | Register</title>
                </Helmet>
                <body>
                <section className="creatAccount register-1">
                    <div className="container-fluid p-0">
                        <div className="row justify-content-center align-items-center no-gutters">
                            <div className="col-md-6">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="createInput">
                                        <Link to="/register"><img src="images/logo.png" className="img-fluid" alt="img"/></Link>
                                        <h2>Create Account</h2>
                                        <div className="grpInpt">
                                            <label>First Name</label>
                                            <input type="text" name="first_name" onChange={e=>this.first_name=e.target.value}/>

                                            <p className=''>{this.state.first_name??''}</p>
                                        </div>
                                        <div className="grpInpt">
                                            <label>Last Name</label>
                                            <input type="text" name="last_name" onChange={e=>this.last_name=e.target.value}/>
                                            <p className=''>{this.state.last_name??''}</p>
                                        </div>
                                        <div className="grpInpt">
                                            <label>Email</label>
                                            <input type="email" required name="email" onChange={e=>this.email=e.target.value}/>
                                            <p className=''>{this.state.email??''}</p>
                                        </div>
                                        <div className="grpInpt">
                                            <label>Real Estate Agent License Number</label>
                                            <input type="text" name="license" onChange={e=>this.license=e.target.value}/>

                                            <p className=''>{this.state.license??''}</p>
                                        </div>

                                        <div className="grpInpt">
                                            <label>MLS </label>
                                            <input type="text"  onChange={e=>this.mls=e.target.value}/>
                                        </div>
                                        <div className="grpInpt">
                                            <label>Password</label>
                                            <input type="password" name="password" onChange={e=>this.password=e.target.value}/>
                                            <p className=''>{this.state.password??''}</p>
                                        </div>
                                        <div className="grpInpt">
                                            <label>Confirm Password</label>
                                            <input type="password" name="cnf_password" onChange={e=>this.cnf_password=e.target.value}/>
                                            <p className=''>{this.state.cnf_password??''}</p>
                                        </div>
                                        <div className="sgnBtn">
                                            <button  className="themeBtn btn-1c">Sign Up</button>
                                            <div className="agreCheck12">
                                                {/*<input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>*/}
                                                {/*<label htmlFor="vehicle1">Remember me</label>*/}
                                            </div>
                                        </div>
                                        <div className="newfield">
                                            <p>Already have an Account?</p>
                                            <Link to="/login">Login</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6">
                                <img src="images/new-11.jpg" className="img-fluid" alt="img"/>
                            </div>
                        </div>
                    </div>
                </section>
                </body>
            </React.Fragment>
        )
    }


}

export default RegisterForm;